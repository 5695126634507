<!--个人中心左侧公共导航 @zjf-2020-12-26-->
<template>
  <div class="sld_member_left_nav">
    <img :src="memberInfo.memberAvatar" class="member_avatar" alt="">
    <div class="member_name">{{memberInfo.memberNickName}}</div>
    <div class="member_name">{{memberInfo.memberName}}</div>
    <div class="member_edit" @click="goToByPush('/member/info',{})"> {{ L['会员信息'] }}</div>

    <router-link class="submenu" :to="{path:'/member/index'}">
      {{ L['首页'] }}
    </router-link>

    <router-link class="submenu" :to="{path:'/member/msg'}">
      {{ L['消息'] }}
    </router-link>

    <template v-for="(item, index) in leftNavMenu" :key="index">
      <router-link :to="{ path: item.path }" class="menu">
        {{ item.name }}
      </router-link>
      <template v-if="item.child.length">
        <template v-for="(item_child, index_child) in item.child" :key="index_child">
          <router-link v-if="testIfShow(item_child)" :to="{ path: item_child.path, query: item_child.query }" :class="{ submenu: true, cur_code_active: curCode == item_child.code }">
            <i :class="{ iconfont: true, [item_child.icon]: true }"></i>
            {{ item_child.name }}</router-link>
        </template>
      </template>
    </template>

    <a href="javascript:;" class="submenu" @click="loginOut">
      <i :class="{ iconfont: true,  }"></i>
      {{ L['退出登录'] }}
    </a>

  </div>
</template>

<script>
import { getCurrentInstance, watchEffect, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
export default {
  name: "MemberLeftNav",
  components: {},
  setup() {
    const store = useStore();
    const router = useRouter();
    const { proxy } = getCurrentInstance();
    const L = proxy.$getCurLanguage();
    const curCode = ref("");
    const memberInfo = ref(store.state.memberInfo);
    watchEffect(() => {
      let tmpPath = router.currentRoute.value.fullPath;

      if (
        router.currentRoute.value.name != undefined &&
        router.currentRoute.value.name
      ) {
        curCode.value = router.currentRoute.value.name;
        if (tmpPath == "/member/collect?type=store") {
          curCode.value = "memberCollectStore"; //关注店铺
        } else if (
          tmpPath == "/member/order/aftersales?type=return" ||
          tmpPath.indexOf("/member/order/refund/detail?curTitleId=2") > -1
        ) {
          curCode.value = "AfterSalesListReturn"; //我的退货
        } else if (
          tmpPath == "/member/order/aftersales" ||
          tmpPath.indexOf("/member/order/refund/detail?curTitleId=1") > -1
        ) {
          curCode.value = "AfterSalesList"; //我的退款
        }
      }
    });
    const leftNavMenu = [
      {
        code: "tarde",
        icon: "icon-gerenzhongxinjiaoyizhongxin",
        name: L["交易中心"],
        path: "/member/index",
        child: [
          {
            code: "memberOrderList",
            name: L["我的订单"],
            path: "/member/order/list"
          },
          {
            code: "MemberOrderEvaluation",
            name: L["交易评价/晒单"],
            path: "/member/order/evaluation"
          },
          // {
          //   code: "pointOrderList",
          //   name: "积分兑换订单",
          //   path: "/member/pointOrder/list"
          // }
        ]
      },
      {
        code: "collect",
        name: L["关注中心"],
        path: "/member/index",
        child: [
          {
            code: "MemberCollect",
            name: L["我的收藏"],
            path: "/member/collect",
            icon: "icongerenzhongxinguanzhu",
          },
          {
            code: "memberCollectStore",
            query: { type: "store" },
            name: L["关注店铺"],
            path: "/member/collect",
            icon: "icongerenzhongxinguanzhu",
          },
          {
            code: "MemberFootprint",
            name: L["我的足迹"],
            path: "/member/footprint",
            icon: "icongerenzhongxinguanzhu",
          }
        ]
      },
      {
        code: "service",
        icon: "icongerenzhongxinkehufuwu",
        name: L["客户服务"],
        path: "/member/index",
        child: [
          {
            code: "AfterSalesList",
            name: L["我的退款"],
            path: "/member/order/aftersales"
          },
          {
            code: "AfterSalesListReturn",
            query: { type: "return" },
            name: L["我的退货"],
            path: "/member/order/aftersales"
          }
        ]
      },
      {
        code: "member",
        icon: "icongerenzhongxinhuiyuanzhongxin",
        name: L["会员中心"],
        path: "/member/index",
        child: [
          {
            code: "MemberAccount",
            name: L["账号安全"],
            path: "/member/account"
          },
          {
            code: "MemberPhoneMange",
            name: L["手机号管理"],
            path: "/member/phone"
          },
          {
            code: "memberEmailMange",
            name: L["邮箱管理"],
            path: "/member/email"
          },
          {
            code: "MemberLoginPassword",
            name: L["登录密码"],
            path: "/member/pwd/login"
          },
          // {
          //   code: "MemberPayPassword",
          //   name: L["支付密码"],
          //   path: "/member/pwd/pay"
          // },
          {
            code: "MemberResetPassword",
            name: L["重置密码"],
            path: "/member/pwd/reset"
          },
          {
            code: "memberAddressList",
            name: L["收货地址"],
            path: "/member/address/list"
          },
          // {
          //   code: "memberLanguage",
          //   name: L["语言切换"],
          //   path: "/member/language"
          // }
        ]
      },
      {
        code: "money",
        icon: "icongerenzhongxincaichanzhongxin",
        name: L["我的钱包"],
        path: "/member/index",
        child: [
          // {
          //   code: "MemberBalance",
          //   name: L["我的余额"],
          //   path: "/member/balance"
          // },
          // {
          //   code: "MemberRecharge",
          //   name: L["账户充值"],
          //   path: "/member/recharge"
          // },
          {
            code: "MemberCoupon",
            name: L["我的优惠券"],
            path: "/member/coupon"
          },
          // {
          //   code: "MyPoint",
          //   name: "我的积分",
          //   path: "/member/myPoint"
          // }

        ]
      },
      // {
      //   code: "anchorCenter",
      //   icon: "icongerenzhongxincaichanzhongxin",
      //   name: L["直播管理"],
      //   path: "/member/index",
      //   child: [
      //     {
      //       code: "anchorCenter",
      //       name: "我的直播",
      //       path: "/member/anchorCenter"
      //     },
      //   ]
      // },
    ];
    const testIfShow = nav_info => {
      if (nav_info.code == "MemberResetPassword") {
        if (store.state.memberInfo.hasPayPassword == 1) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    };

    //退出登录
    const loginOut = () => {
      store.commit("clearAllData"); //清除数据
      store.commit('clearCartStorage')
      proxy.$socket.disconnect()
      goToByPush("/", {});
    };
    //前往具体的页面，type为具体的页面地址，param为参数对象
    const goToByPush = (type, param = {}) => {
      if (type == "/service") {
        let chatInfo = {
          storeId: 0,
          vendorAvatar: configInfo.platform_customer_service_logo,
          storeName: configInfo.platform_customer_service_name,
          showData: {},
          source: "pc商城"
        };
        store.commit("saveChatBaseInfo", chatInfo);

        let newWin = router.resolve({
          path: type,
          query: param
        });

        window.open(newWin.href, "_blank");
        return;
      }

      router.push({
        path: type,
        query: param
      });
    };

    return { L, leftNavMenu, curCode, memberInfo, testIfShow, loginOut, goToByPush };
  }
};
</script>

<style lang="scss" scoped>
.sld_member_left_nav {
  float: left;
  width: 256px;
  color: #666;
  font-size: 12px;
  font-family: MicrosoftYaHei;
  background-color: #fff;
  margin-bottom: 20px;
  padding-bottom: 40px;
  margin-right: 24px;
  .member_avatar {
    width: 144px;
    height: 144px;
    border-radius: 50%;
    margin-bottom: 16px;
  }
  .member_name {
    font-size: 16px;
    margin-bottom: 4px;
  }
  .member_edit {
    background: #005cf6;
    display: inline-block;
    padding: 0 24px;
    height: 28px;
    margin-bottom: 24px;
    border-radius: 16px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    line-height: 28px;
    font-weight: 500;
    cursor: pointer;
  }

  .menu {
    display: block;
    color: #333333;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 10px;
    padding-top: 22px;

    &:nth-child(1) {
      margin-top: 0;
      border-top: none;
    }

    i {
      color: #888;
      font-size: 16px;
      font-weight: 400;
      margin-right: 5px;
    }
  }

  .submenu {
    display: block;
    height: 56px;
    color: #1b1b1b;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    display: flex;
    padding-left: 16px;
    align-items: center;
    .iconfont {
      margin-right: 12px;
    }

    &:hover {
      background: #dbe1ff;
      border-radius: 12px;
    }

    &.cur_code_active {
      background: #dbe1ff;
      border-radius: 12px;
    }
  }
}
</style>