
<template>
	<div>
		<div class="tit">
			<img src="@/assets/Group.png" alt="" />
		</div>
		<div class="con">
			<div class="info_con">

				<div class="info">
					<div class="info_tit">
						{{ L['请输入以下信息'] }}
					</div>
					<div class="info_desc">
						<div class="info_item">
							<div class="info_img">
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
									fill="none">
									<mask id="mask0_3959_67232" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0"
										y="0" width="24" height="24">
										<rect width="24" height="24" fill="#D9D9D9" />
									</mask>
									<g mask="url(#mask0_3959_67232)">
										<path
											d="M12 12C10.9 12 9.95833 11.6083 9.175 10.825C8.39167 10.0417 8 9.1 8 8C8 6.9 8.39167 5.95833 9.175 5.175C9.95833 4.39167 10.9 4 12 4C13.1 4 14.0417 4.39167 14.825 5.175C15.6083 5.95833 16 6.9 16 8C16 9.1 15.6083 10.0417 14.825 10.825C14.0417 11.6083 13.1 12 12 12ZM18 20H6C5.45 20 4.97917 19.8042 4.5875 19.4125C4.19583 19.0208 4 18.55 4 18V17.2C4 16.6333 4.14583 16.1125 4.4375 15.6375C4.72917 15.1625 5.11667 14.8 5.6 14.55C6.63333 14.0333 7.68333 13.6458 8.75 13.3875C9.81667 13.1292 10.9 13 12 13C13.1 13 14.1833 13.1292 15.25 13.3875C16.3167 13.6458 17.3667 14.0333 18.4 14.55C18.8833 14.8 19.2708 15.1625 19.5625 15.6375C19.8542 16.1125 20 16.6333 20 17.2V18C20 18.55 19.8042 19.0208 19.4125 19.4125C19.0208 19.8042 18.55 20 18 20ZM6 18H18V17.2C18 17.0167 17.9542 16.85 17.8625 16.7C17.7708 16.55 17.65 16.4333 17.5 16.35C16.6 15.9 15.6917 15.5625 14.775 15.3375C13.8583 15.1125 12.9333 15 12 15C11.0667 15 10.1417 15.1125 9.225 15.3375C8.30833 15.5625 7.4 15.9 6.5 16.35C6.35 16.4333 6.22917 16.55 6.1375 16.7C6.04583 16.85 6 17.0167 6 17.2V18ZM12 10C12.55 10 13.0208 9.80417 13.4125 9.4125C13.8042 9.02083 14 8.55 14 8C14 7.45 13.8042 6.97917 13.4125 6.5875C13.0208 6.19583 12.55 6 12 6C11.45 6 10.9792 6.19583 10.5875 6.5875C10.1958 6.97917 10 7.45 10 8C10 8.55 10.1958 9.02083 10.5875 9.4125C10.9792 9.80417 11.45 10 12 10Z"
											fill="#1B1B1B" />
									</g>
								</svg>
							</div>
							<div class="info_inp">
								<input type="text" v-model="memberTrueName" :placeholder="L['真实姓名']">
							</div>
						</div>
						<div class="info_item">
							<div class="info_img">
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
									fill="none">
									<mask id="mask0_3959_67237" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0"
										y="0" width="24" height="24">
										<rect width="24" height="24" fill="#D9D9D9" />
									</mask>
									<g mask="url(#mask0_3959_67237)">
										<path
											d="M12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12V13.45C22 14.4333 21.6625 15.2708 20.9875 15.9625C20.3125 16.6542 19.4833 17 18.5 17C17.9167 17 17.3667 16.875 16.85 16.625C16.3333 16.375 15.9 16.0167 15.55 15.55C15.0667 16.0333 14.5208 16.3958 13.9125 16.6375C13.3042 16.8792 12.6667 17 12 17C10.6167 17 9.4375 16.5125 8.4625 15.5375C7.4875 14.5625 7 13.3833 7 12C7 10.6167 7.4875 9.4375 8.4625 8.4625C9.4375 7.4875 10.6167 7 12 7C13.3833 7 14.5625 7.4875 15.5375 8.4625C16.5125 9.4375 17 10.6167 17 12V13.45C17 13.8833 17.1417 14.25 17.425 14.55C17.7083 14.85 18.0667 15 18.5 15C18.9333 15 19.2917 14.85 19.575 14.55C19.8583 14.25 20 13.8833 20 13.45V12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20H16C16.2833 20 16.5208 20.0958 16.7125 20.2875C16.9042 20.4792 17 20.7167 17 21C17 21.2833 16.9042 21.5208 16.7125 21.7125C16.5208 21.9042 16.2833 22 16 22H12ZM12 15C12.8333 15 13.5417 14.7083 14.125 14.125C14.7083 13.5417 15 12.8333 15 12C15 11.1667 14.7083 10.4583 14.125 9.875C13.5417 9.29167 12.8333 9 12 9C11.1667 9 10.4583 9.29167 9.875 9.875C9.29167 10.4583 9 11.1667 9 12C9 12.8333 9.29167 13.5417 9.875 14.125C10.4583 14.7083 11.1667 15 12 15Z"
											fill="#1B1B1B" />
									</g>
								</svg>
							</div>
							<div class="info_inp">
								<input type="text" v-model="memberEmail" :placeholder="L['邮箱']">
							</div>
						</div>
						<div class="info_item">
							<div class="info_img">
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
									fill="none">
									<mask id="mask0_3959_67242" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0"
										y="0" width="24" height="24">
										<rect width="24" height="24" fill="#D9D9D9" />
									</mask>
									<g mask="url(#mask0_3959_67242)">
										<path
											d="M12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM11 19.95V18C10.45 18 9.97917 17.8042 9.5875 17.4125C9.19583 17.0208 9 16.55 9 16V15L4.2 10.2C4.15 10.5 4.10417 10.8 4.0625 11.1C4.02083 11.4 4 11.7 4 12C4 14.0167 4.6625 15.7833 5.9875 17.3C7.3125 18.8167 8.98333 19.7 11 19.95ZM17.9 17.4C18.2333 17.0333 18.5333 16.6375 18.8 16.2125C19.0667 15.7875 19.2875 15.3458 19.4625 14.8875C19.6375 14.4292 19.7708 13.9583 19.8625 13.475C19.9542 12.9917 20 12.5 20 12C20 10.3667 19.5458 8.875 18.6375 7.525C17.7292 6.175 16.5167 5.2 15 4.6V5C15 5.55 14.8042 6.02083 14.4125 6.4125C14.0208 6.80417 13.55 7 13 7H11V9C11 9.28333 10.9042 9.52083 10.7125 9.7125C10.5208 9.90417 10.2833 10 10 10H8V12H14C14.2833 12 14.5208 12.0958 14.7125 12.2875C14.9042 12.4792 15 12.7167 15 13V16H16C16.4333 16 16.825 16.1292 17.175 16.3875C17.525 16.6458 17.7667 16.9833 17.9 17.4Z"
											fill="#1B1B1B" />
									</g>
								</svg>
							</div>
							<div class="select_inp">
								<!-- <el-select v-model="country" :placeholder="L['国家']" class="el-select-con">
								<el-option :label="L['中国']" value="CN"></el-option>
								<el-option :label="L['哈萨克斯坦']" value="KZ"></el-option>
							</el-select> -->

								<el-select v-model="country" :popper-append-to-body="false" :placeholder="L['国家']"
									class="el-select-con">
									<el-option :label="L[item.cn]" :value="item.regionCode"
										v-for="(item, index) in areaMobileList" :key="index"
										@click="country = item.regionCode">
										<div class="area-code-item">
											<img class="flagImg" :src="item.img" alt="">
											<span>{{ L[item.cn] }}</span>
										</div>
									</el-option>
								</el-select>

								<svg class="icon_down" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
									viewBox="0 0 24 24" fill="none">
									<mask id="mask0_3959_67250" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0"
										y="0" width="24" height="24">
										<rect width="24" height="24" fill="#D9D9D9" />
									</mask>
									<g mask="url(#mask0_3959_67250)">
										<path
											d="M11.2966 14.3L8.69656 11.7C8.37989 11.3833 8.30906 11.0208 8.48406 10.6125C8.65906 10.2042 8.97156 10 9.42156 10H14.5716C15.0216 10 15.3341 10.2042 15.5091 10.6125C15.6841 11.0208 15.6132 11.3833 15.2966 11.7L12.6966 14.3C12.5966 14.4 12.4882 14.475 12.3716 14.525C12.2549 14.575 12.1299 14.6 11.9966 14.6C11.8632 14.6 11.7382 14.575 11.6216 14.525C11.5049 14.475 11.3966 14.4 11.2966 14.3Z"
											fill="#1B1B1B" />
									</g>
								</svg>
							</div>
						</div>

						<div class="next" @click="next">
							{{ L['下一步'] }}
						</div>

					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import { areaMobile } from '@/utils/data.js'
import { reactive, getCurrentInstance, ref, onMounted, onUnmounted } from 'vue'
import { ElMessage, ElInput } from 'element-plus'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
export default {
	name: 'bindCard',
	components: {
		ElInput
	},
	setup() {

		const route = useRoute()
		const router = useRouter()
		const store = useStore()
		const { proxy } = getCurrentInstance()
		const L = proxy.$getCurLanguage()

		const memberInfo = reactive({ data: store.state.memberInfo })

		const areaMobileList = reactive(areaMobile)

		const memberTrueName = memberInfo.data.memberTrueName ? ref(memberInfo.data.memberTrueName) : ref('')
		const memberEmail = memberInfo.data.memberEmail ? ref(memberInfo.data.memberEmail) : ref('')
		const country = memberInfo.data.country ? ref(memberInfo.data.country) : ref('')

		onUnmounted(() => {

		})

		const next = () => {
			if (!memberTrueName.value) {
				ElMessage.warning(L['请输入真实姓名'])
				return
			}
			if (!memberEmail.value) {
				ElMessage.warning(L['请输入邮箱'])
				return
			}
			if (!memberEmail.value.includes('@')) {
				ElMessage.warning(L['请输入正确邮箱'])
				return
			}
			if (!country.value) {
				ElMessage.warning(L['请选择地区'])
				return
			}

			let param = {}
			param.memberTrueName = memberTrueName.value
			param.memberEmail = memberEmail.value
			param.country = country.value

			proxy.$get('v3/member/front/member/bindTazapay', param)
				.then(res => {
					if (res.state == 200) {
						// 更新信息
						proxy.$get('v3/member/front/member/getInfo').then(res => {
							if (res.state == 200) {
								memberInfo.info = res.data
								store.commit('updateMemberInfo', memberInfo.info) //将购物车数据存储到vuex的store中
							}
						})

						let param2 = route.query
						//请求支付接口
						proxy.$post('v3/business/front/orderPay/toPay', param2).then(res => {
							if (res.state == 200) {
								window.location = res.data;
							} else {
								ElMessage(res.msg)
							}
						}).catch(() => {
							//异常处理
						})

					} else {
						ElMessage(res.msg)
					}
				})
				.catch(() => {
					//异常处理
				})
		}

		return {
			L,
			memberTrueName,
			memberEmail,
			country,
			next,
			areaMobileList
		}
	},

	beforeRouteEnter(to, from, next) {
		// ...
		if (from.name == 'Login') {
			next('/index')
		} else {
			next()
		}
	}
}
</script>
<style lang="scss">
// body,
// html {
// 	background: #F4F5F7;
// }

.select_inp {
	width: 100%;
	position: relative;

	.el-select-con {
		width: 100%;
	}

	.el-input__inner {
		border: 1px solid #C6C6C6;
		height: 48px;
		width: 100%;
	}

	.el-input__inner::placeholder {
		color: #919191;

		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		/* 150% */
		letter-spacing: 0.5px;
	}

	.el-input__suffix {
		display: none;
	}

	.icon_down {
		position: absolute;
		right: 8px;
		top: 12px;
	}

	.area-code-item {
		display: flex;
		align-items: center;

		.flagImg {
			height: 20px;
			margin-right: 13px;
			width: 20px;
			border-radius: 100%;
		}
	}
}
</style>
<style lang="scss" scoped>
@import '../../style/base.scss';

.tit {
	position: relative;
	z-index: 9;
	display: flex;
	padding: 24px 0px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
	background: #FFF;
	box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.08);
}

.con {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: #F4F5F7;
	.info_con {
		position: fixed;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		display: flex;
		width: 536px;
		padding: 40px;
		flex-direction: column;
		align-items: flex-start;
		gap: 56px;
		border-radius: 4px;
		background: #FFF;
		box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.10);

	}

	.info {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 24px;
		align-self: stretch;

		.info_tit {
			color: #000;
			text-align: center;
			/* Title/Large */
			font-size: 22px;
			font-weight: 400;
			line-height: 28px;
			/* 127.273% */
		}

		.info_desc {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 44px;
			align-self: stretch;

			.info_item {
				display: flex;
				align-items: center;
				gap: 8px;
				align-self: stretch;

				.info_img {
					padding: 12px;
				}

				.info_inp {
					display: flex;
					padding: 0 16px;
					height: 48px;
					flex-direction: column;
					justify-content: center;
					align-items: flex-start;
					flex: 1 0 0;

					border-radius: 4px;
					border: 1px solid #C6C6C6;

					input {
						width: 100%;
						color: #919191;

						/* Body/Large */
						font-size: 16px;
						font-weight: 400;
						line-height: 24px;
						/* 150% */
						letter-spacing: 0.5px;
					}
				}

			}

			.next {
				display: flex;
				width: 400px;
				height: 56px;
				padding: 12px 24px;
				justify-content: center;
				align-items: center;
				gap: 8px;
				flex: 1 0 0;
				border-radius: 4px;
				background: linear-gradient(108deg, #B2F000 12.4%, #1FA7D9 87.6%);
				color: #FFF;
				text-align: center;

				/* Body/Large SemiBold */
				font-size: 16px;
				font-weight: 600;
				line-height: 24px;
				/* 150% */
				letter-spacing: 0.5px;
				margin-left: 56px;
			}
		}
	}
}</style>
