// 商品详情页面
<template>
	<div>
		<!-- <StoreHeaderCat ref="headercat" @updateFllow="updateFllow" /> -->
		<SldHomeTopSearch />
		<NavCatHeader />
		<div class="sld_goods_detail" @click.stop="couponShow = false">
			<!-- 内容区顶部固定 start -->
			<div class="contain_con" v-if="containCon">
				<div class="contain_content flex_row_center_center">
					<div class="contain_con_left flex_row_start_center">
						<!-- <span class="store_type" v-if="goodsDetail.data.storeInf.isOwnStore == 1">{{
							L['自营']
						}}</span> -->
						<span class="store_title">{{
							goodsDetail.data.storeInf.storeName
						}}</span>
						<img src="@/assets/goods/link_server.png" @click="kefu" />
					</div>
					<div class="contain_con_right">
						<div class="goods_description_title flex_row_between_center">
							<div class="description_title_left flex_row_start_center">
								<span class="cursor_pointer" :class="{ description_active: goodsDesctionType == 'detail' }"
									@click="goodsDescType('detail')">{{ L['商品详情'] }}</span>
								<span class="cursor_pointer" :class="{
									description_active: goodsDesctionType == 'evaluate'
								}" @click="goodsDescType('evaluate')">{{ L['评价'] }}({{ goodsCommentsInfo.data &&
	goodsCommentsInfo.data.commentsCount > 0 ? goodsCommentsInfo.data.commentsCount : '0'
}})</span>
								<span class="cursor_pointer" :class="{
										description_active: goodsDesctionType == 'service'
									}" @click="goodsDescType('service')">{{ L['商品服务'] }}</span>
								<span class="cursor_pointer" :class="{
									description_active: goodsDesctionType == 'salestore'
								}" @click="goodsDescType('salestore')">{{ L['店铺热销'] }}</span>
							</div>
							<div class="description_title_right flex_row_end_center">
								<div class="mobile_order flex_row_start_center cursor_pointer">
									<span>{{ L['手机下单'] }}</span>
									<div class="goods_code" id="qrcode"></div>
									<div class="mobile_order_model">
										<div class="model_goods_code" id="qrcodeBig1">
										</div>
										<p>{{ L['手机下单'] }}</p>
									</div>
								</div>
								<div class="line" v-if="goodsDetail.data.isVirtualGoods == 1 &&
									!goodsDetail.data.defaultProduct.promotionType
									"></div>
								<div class="go_add_cart cursor_pointer" @click="addCart" v-if="goodsDetail.data.isVirtualGoods == 1 &&
									!goodsDetail.data.defaultProduct.promotionType
									">
									{{ L['加入购物车'] }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 内容区顶部固定 end -->
			<div class="goods_detail_content" v-if="goodsDetail.data">
				<!-- 商品所属分类 ，联系客服，关注店铺 start-->
				<div class="goods_about_con">
					<div class="goods_about flex_row_between_center">
						<div class="goods_classify">
							<span>
								<router-link :to="`/index`
									">
									<!-- <img  class="img_home" src="@/assets/home.png" alt=""> -->
									<em></em>
									{{ L['首页'] }}
								</router-link>
								<img src="@/assets/right.png" alt="">
							</span>
							<span v-for="(item, index) in goodsDetail.data.categoryPath" :key="index">

								<router-link :to="`/goods/list?categoryId=${goodsDetail.data.categoryIdPath[index]
									}&g=${index + 1}${index > 0
										? '&pid=' + goodsDetail.data.categoryIdPath[index - 1]
										: '&pid=0'
									}`
									">
									{{ item }}</router-link>
								<!-- <i> > </i> -->
								<img src="@/assets/right.png" alt="">
							</span>
							<span style="color: #1B1B1B;padding: 0 8px;">{{
								goodsDetail.data.goodsName
							}}</span>
						</div>

					</div>
				</div>
				<!-- 商品所属分类 ，联系客服，关注店铺 end-->

				<div class="panel">
					<div class="panel_left">
						<!-- 产品图 -->
						<div class="preview_con">
							<div class="preview_left">
								<div class="show_box" ref="show_box1">
									<!-- {{ goodsDetail.data.defaultProduct && goodsDetail.data.defaultProduct.goodsPics }} -->
									<div
										v-if="goodsDetail.data.defaultProduct && goodsDetail.data.defaultProduct.goodsPics && goodsDetail.data.defaultProduct.goodsPics.length > 0">
										<li :class="{ goods_img: true, on: currentDefaultImage == goodsImgIndex }" v-for="(goodsImgItem, goodsImgIndex) in goodsDetail.data
											.defaultProduct.goodsPics" :key="goodsImgIndex" @click="selectDefaultImage(goodsImgItem, goodsImgIndex)">
											<div class="goods_image" :style="{
												backgroundImage: 'url(' + goodsImgItem + ')'
											}">
												<img v-if="goodsImgIndex == 0 && goodsDetail.data.goodsVideo"
													class="icon_play" src="@/assets/play_button1.png" alt="">
											</div>
										</li>
									</div>

								</div>
							</div>
							<div class="preview_right">

								<div class="imageBorder" :class="{
									default_image: true,
									skeleton_default_image: firstLoading
								}" :style="{ backgroundImage: 'url(' + defaultImage + ')' }" @click.stop="clickDefaultImage">
									<video v-if="currentDefaultImageShow == true && goodsDetail.data.goodsVideo" controls
										playsinline="playsinline" class="imageBorder default_image" :poster="defaultImage"
										autoplay ref="video">
										<source :src="goodsDetail.data.goodsVideo" type="video/mp4" />
									</video>
									<div v-if="currentDefaultImageShow == false && currentDefaultImage == 0 && goodsDetail.data.goodsVideo"
										class="icon_play_con">
										<img @click.stop="playV" class="icon_play" src="@/assets/play_button1.png" alt="">
									</div>
								</div>


								<!-- <div class="v_btn" v-if="currentDefaultImage != -1 && goodsDetail.data.goodsVideo
									">
									<img src="@/assets/goods/playV.png" alt="" @click="playV" />
								</div> -->
							</div>
						</div>
						<!-- 评论 -->
						<div class="comment_con">
							<div class="comment_tit">
								<span class="tit">
									{{
										goodsCommentsInfo.data.commentsCount
									}} {{ L['评价'] }}
									<span class="line"></span>
									<el-rate disabled :colors="colors" score-template="{score}" v-model="score">
									</el-rate>
									<span class="score">
										{{ score }}
									</span>
								</span>
								<span class="tit2">
									<img src="@/assets/shield_person.png" alt="">
									{{ L['所有评论已认证真实订单'] }}
								</span>
							</div>
							<div class="desc_tit">
								<div class="desc_list">
									<div :class="[evaluationType == '' ? 'on' : '', 'desc_pointer']"
										@click="getEvaluation('')">
										<img v-show="evaluationType == ''" src="@/assets/check3.png" alt="">
										{{ L['全部评价'] }}
									</div>
									<div class="desc_pointer"
										:class="[evaluationType == 'hasPic' ? 'on' : '', 'desc_pointer']"
										@click="getEvaluation('hasPic')">
										<img v-show="evaluationType == 'hasPic'" src="@/assets/check3.png" alt="">
										{{ L['有图/有视频'] }}({{ goodsCommentsInfo.data.hasPicCount }})
									</div>
									<div class="desc_pointer" :class="[evaluationType == 'low' ? 'on' : '', 'desc_pointer']"
										@click="getEvaluation('low')">
										<img v-show="evaluationType == 'low'" src="@/assets/check3.png" alt="">
										{{ L['低评价'] }}({{ goodsCommentsInfo.data.lowCount }})
									</div>
								</div>
							</div>
							<div class="comment_list" v-if="goodsCommentsInfo.data &&
								goodsCommentsInfo.data.list &&
								goodsCommentsInfo.data.list.length > 0
								">
								<div class="comment_item" v-for="(goodsCommentsItem,
									goodsCommentsIndex) in goodsCommentsInfo.data.list" :key="goodsCommentsIndex">
									<div class="comment_item_tit">
										<div class="comment_item_left">

											<div class="comments_avatar" :style="{
												backgroundImage:
													'url(' + goodsCommentsItem.memberAvatar + ')'
											}"></div>
											<div class="comments_desc">
												<div class="comments_name">
													{{ goodsCommentsItem.memberName }}
												</div>
												<div class="comments_specValues">
													{{goodsCommentsItem.specValues}}
												</div>
											</div>

										</div>
										<div class="comment_item_right">
											<div class="comments_time">
												{{ $formatDate(goodsCommentsItem.createTime, false) }}
											</div>
											<el-rate v-model="goodsCommentsItem.score" disabled text-color="#F1F1F1"
												allow-half="true" :colors="colors">
											</el-rate>
										</div>
									</div>

									<div class="comment_item_pre">
										<p class="CommentContent">{{ goodsCommentsItem.content }}</p>
										<div class="comment_pre_img" v-if="goodsCommentsItem.images.length">
											<div class="imgCon1" v-for="(commentsImgItem,
												commentsImgIndex) in goodsCommentsItem.images" :key="commentsImgIndex">

												<div class="list_pre_img" :style="{
													backgroundImage:
														'url(' + commentsImgItem + ')'
												}" @click="showImg(commentsImgIndex, commentsImgItem)"></div>
											</div>

										</div>
									</div>
								</div>
							</div>

							<div v-if="goodsCommentsInfo.data.list &&
								goodsCommentsInfo.data.list.length == 0
								" class="flex_column_center_center empty_data">
								<SldCommonEmpty :tip="L['该商品暂无评论~']" totalWidth="934px" />
							</div>

							<div v-if="goodsCommentsInfo.data.pagination">
								<div class="sld_pagination2 " v-if="goodsCommentsInfo.data.pagination.total">
									<el-pagination @current-change="handleCurrentChange"
										v-model:currentPage="evaluationCurrent" :page-size="evaluationPageSize"
										layout="prev, pager, next" :total="goodsCommentsInfo.data.pagination.total"
										:hide-on-single-page="true">
									</el-pagination>
								</div>
							</div>
						</div>

						<!-- 商家详情 -->
						<div class="store_con">
							<div class="store_left">
								<div class="store_left_info_con">
									<img class="head_img" :src="storeData.info.storeLogoUrl">
									<div class="store_left_info">
										<div class="p1">
											<img src="@/assets/Verified.png" alt="">
											{{ L['认证商家'] }}
										</div>
										<div class="p2">
											{{ storeData.info.storeName }}
										</div>
										<div class="p3">
											{{
												storeData.info.mainBusiness
												? storeData.info.mainBusiness
													.replace(/(.*),/, '$1。')
													.replace(/,/g, '、')
												: '--'
											}}
										</div>
									</div>
								</div>
								<div class="store_left_list">
									<div class="store_left_item">
										<p class="p1">
											<img src="@/assets/Stars.png" alt="">
											{{
												storeData.info.comprehensiveScore
											}}/5
										</p>
										<p class="p2">
											{{ L['店铺星级'] }}
										</p>
									</div>
									<div class="store_left_item">
										<p class="p1">
											{{ storeData.info.followNumber }}
										</p>
										<p class="p2">
											{{ L['关注人数'] }}
										</p>
									</div>
									<div class="store_left_item" v-show="false">
										<p class="p1">
											96
										</p>
										<p class="p2">
											{{ L['商品总数'] }}
										</p>
									</div>
								</div>
							</div>
							<div class="store_right">
								<div class="contact_service" @click="kefu">
									<img src="@/assets/support_agent.png" alt="">
									{{ L['联系客服'] }}
								</div>
								<div class="contact_store ">
									<router-link :to="`/store/goods?vid=${vid}`" class="go_store_btn">
										<img src="@/assets/Store.png" alt="">
										{{ L['进入店铺'] }}
									</router-link>
								</div>
							</div>

						</div>

						<!-- 店铺推荐 -->
						<div class="store_recommend_con">
							<div class="store_recommend_tit" v-if="goodsDetail.data.storeInf">
								<router-link target="_blank" :to="`/store/goods?vid=${goodsDetail.data.storeInf.storeId}`">
									{{ L['店铺推荐'] }}
									<img src="@/assets/icon-right2.png" alt="">
								</router-link>
							</div>
							<div class="store_recommend_list" v-if="storePopularList.data.length">
								<div class="store_recommend_item" v-for="(storePopularItem,
									storePopularIndex) in storePopularList.data" :key="storePopularIndex"
									@click="goGoodsDetail(storePopularItem.defaultProductId)">
									<img :src="storePopularItem.goodsImage" :title="storePopularItem.goodsName">
									<p class="price">
										<span>
											<span class="money_number">{{ $formatMoney(storePopularItem.goodsPrice) }} {{
												L['₸'] }}</span>
										</span>
									</p>
									<p class="title">
										<a href="javascript:void(0)">{{ storePopularItem.goodsName }}</a>
									</p>
								</div>
							</div>
						</div>

						<!-- 宝贝详情 -->
						<div class="goods_html_con">
							<div class="goods_html_tit">
								{{ L['宝贝详情'] }}
							</div>

							<div v-if="goodsDetail.data.topTemplateContent != undefined &&
								goodsDetail.data.topTemplateContent
								" class="goods_html " :style="goodsDetail.data.topTemplateContentBg">
								<div :class="[goodsHtmlShow == true ? '' : 'hidden_html', 'goods_htmls']"
									v-html="goodsDetail.data.topTemplateContent">
								</div>
							</div>
							<div v-if="goodsDetail.data.goodsDetails != undefined &&
								goodsDetail.data.goodsDetails
								" class="goods_html" :style="goodsDetail.data.goodsDetailsBg">
								<div :class="[goodsHtmlShow == true ? '' : 'hidden_html', 'goods_htmls']"
									v-html="goodsDetail.data.goodsDetails">
								</div>
							</div>
							<div v-if="goodsDetail.data.bottomTemplateContent != undefined &&
								goodsDetail.data.bottomTemplateContent
								" class="goods_html" :style="goodsDetail.data.bottomTemplateContentBg">
								<div :class="[goodsHtmlShow == true ? '' : 'hidden_html', 'goods_htmls']"
									v-html="goodsDetail.data.bottomTemplateContent">
								</div>
							</div>

							<div class="more_con" @click="goodsHtmlMore" v-show="!goodsHtmlShow">
								<div class="bg"></div>
								<div class="more">
									{{ L['查看全部'] }} <img src="@/assets/icon-down.png" alt="">
								</div>
							</div>
						</div>

					</div>
					<div class="panel_right">
						<span class="tit">
							{{ goodsDetail.data.goodsName }}
						</span>
						<div class="ziying_con">
							<div class="ziying">
								<!-- <img class="ziying_bg_02" src="../assets/shop/sld_bg.png" alt="" srcset="" /> -->
								<div class="triangle"></div>
								<div class="txt">
									<span> {{ L['продано от dzon'] }}</span>
								</div>
								<div class="triangle2"></div>
							</div>
						</div>
						<div class="desc">
							<span class="actual_sales">{{ goodsDetail.data.sales }} {{ L['人已付款'] }}</span>
							<router-link target="_blank" :to="`/store/index?vid=${goodsDetail.data.storeInf.storeId}`"
								v-if="goodsDetail.data.storeInf">
								<span class="store_tit">{{ goodsDetail.data.storeInf.storeName }}</span>
								<img src="@/assets/right2.png" alt="">
							</router-link>
						</div>
						<div :class="{
							act_banner: true,
							sec_not: seckillInfo.data.state == 1,
							sec_do: seckillInfo.data.state == 2
						}" v-if="JSON.stringify(seckillInfo.data) != '{}'">
							<div class="title">{{ L['秒杀'] }}</div>

							<div class="time">
								<span class="countDown1 countDown1_seck">{{ time.hours }}</span>
								<span class="countDown2">:</span>
								<span class="countDown1 countDown1_seck">{{ time.minutes }}</span>
								<span class="countDown2">:</span>
								<span class="countDown1 countDown1_seck">{{ time.seconds }}</span>
								<span v-if="time.day" class="countDown0">{{ time.day }}
								</span>
								<span v-if="time.day">
									{{
										L['天']
									}}
								</span>
							</div>

						</div>
						<div v-if="goodsDetail.data.defaultProduct">
							<div class="p_price" v-if="goodsDetail.data.defaultProduct.productPrice">
								<!-- 在售价 -->
								<span class="new_price">
									<span v-if="JSON.stringify(preSellInfo.data) != '{}' &&
										preSellInfo.data.pre_run == 2
										">
										{{ $formatMoney(preSellInfo.data.presellPrice) &&
											$formatMoney(preSellInfo.data.presellPrice.toFixed(2)) }}
									</span>
									<span v-else-if="JSON.stringify(pinInfo.data) != '{}' &&
										pinInfo.data.state == 1
										">
										{{ $formatMoney(pinInfo.data.spellPrice) &&
											$formatMoney(pinInfo.data.spellPrice.toFixed(2)) }}
									</span>
									<span v-else-if="JSON.stringify(ladderInfo.data) != '{}' &&
										ladderInfo.data.state == 2
										">
										{{ $formatMoney(ladderInfo.data.currentPrice) &&
											$formatMoney(ladderInfo.data.currentPrice.toFixed(2)) }}
									</span>
									<span v-else-if="JSON.stringify(seckillInfo.data) != '{}' &&
										seckillInfo.data.state == 2
										">
										{{
											$formatMoney(seckillInfo.data.seckillPrice) &&
											$formatMoney(seckillInfo.data.seckillPrice.toFixed(2))
										}}
									</span>
									<span v-else>
										{{
											$formatMoney(goodsDetail.data.defaultProduct.productPrice)
											&& $formatMoney(goodsDetail.data.defaultProduct.productPrice.toFixed(2))
										}}
									</span>
									{{
										L['₸']
									}}
								</span>
								<!-- 市场价,预售、拼团、秒杀，阶梯团原价 -->
								<!-- <span>{{ $formatMoney(8999) }}₸</span> -->
								<section>
									<!-- 预售 -->
									<template v-if="JSON.stringify(preSellInfo.data) != '{}'">
										<div class="market_price activity_style" v-if="preSellInfo.data.pre_run == 1">
											{{ $formatMoney(preSellInfo.data.presellPrice)
												&& $formatMoney(preSellInfo.data.presellPrice.toFixed(2))
											}}{{
	L['预售价 ₸']
}}
										</div>
										<div class="market_price" v-if="preSellInfo.data.pre_run == 2">
											{{ $formatMoney(preSellInfo.data.productPrice)
												&& $formatMoney(preSellInfo.data.productPrice.toFixed(2))
											}}
											{{
												L['原价 ₸']
											}}
										</div>
									</template>

									<!-- 拼团 -->
									<template v-else-if="JSON.stringify(pinInfo.data) != '{}'">
										<div class="market_price activity_style" v-if="pinInfo.data.state == 0">
											{{ $formatMoney(pinInfo.data.spellPrice)
												&& $formatMoney(pinInfo.data.spellPrice.toFixed(2)) }}
											{{
												L['拼团价 ₸']
											}}
										</div>
										<div class="market_price" v-if="pinInfo.data.state == 1">
											{{ $formatMoney(pinInfo.data.productPrice)
												&& $formatMoney(pinInfo.data.productPrice.toFixed(2)) }}
											{{
												L['原价 ₸']
											}}
										</div>
									</template>

									<!-- 阶梯团 -->
									<template v-else-if="JSON.stringify(ladderInfo.data) != '{}'
											">
										<div class="market_price activity_style" v-if="ladderInfo.data.state == 1">
											{{ $formatMoney(ladderInfo.data.currentPrice)
												&& $formatMoney(ladderInfo.data.currentPrice.toFixed(2)) }}
											{{
												L['阶梯价 ₸']
											}}
										</div>
										<div class="market_price" v-if="ladderInfo.data.state == 2">
											{{ $formatMoney(ladderInfo.data.productPrice)
												&& $formatMoney(ladderInfo.data.productPrice.toFixed(2)) }}
											{{
												L['原价 ₸']
											}}
										</div>
									</template>

									<!-- 秒杀 -->
									<template v-else-if="JSON.stringify(seckillInfo.data) != '{}'
											">
										<div class="market_price activity_style" v-if="seckillInfo.data.state == 1">
											{{ $formatMoney(seckillInfo.data.seckillPrice)
												&& $formatMoney(seckillInfo.data.seckillPrice.toFixed(2))
											}}
											{{
												L['秒杀价 ₸']
											}}
										</div>
										<div class="market_price" v-if="seckillInfo.data.state == 2">
											{{ $formatMoney(seckillInfo.data.productPrice)
												&& $formatMoney(seckillInfo.data.productPrice.toFixed(2))
											}}
											{{
												L['原价 ₸']
											}}
										</div>
									</template>

									<div class="market_price" v-else-if="goodsDetail.data.defaultProduct.marketPrice
											">
										{{ $formatMoney(goodsDetail.data.defaultProduct.marketPrice)
										}}
										{{
											L['₸']
										}}
									</div>
								</section>
								<span class="reduce"
									v-show="goodsDetail.data.defaultProduct.marketPrice > goodsDetail.data.defaultProduct.productPrice">
									-{{ Math.floor((goodsDetail.data.defaultProduct.marketPrice -
										goodsDetail.data.defaultProduct.productPrice) /
										goodsDetail.data.defaultProduct.marketPrice * 100) }}%
								</span>
							</div>
						</div>
						<!-- 领卷下拉框 -->
						<div class="coupon_con">
							<div class="coupon_box" v-if="couponList.data.list && couponList.data.list.length != 0"
								@click.stop="clickCoupon">
								<!-- 领取后的 -->
								<!-- <div class="coupon_item on">
								<i></i>
								<div class="txt_con">
									<span class="gou"></span>
									<span class="txt">优惠券100-200</span>
								</div>

								<em></em>
							</div> -->
								<!-- 未领取 -->
								<div :class="couponItem.isReceive == 2 ? 'coupon_item on' : 'coupon_item '"
									v-for="(couponItem, couponIndex) in couponList.data.list.slice(0, 3)"
									:key="couponIndex">
									<i></i>
									<div class="txt_con">
										<span class="gou"></span>
										<span class="txt">{{ couponItem.couponContent }}</span>
									</div>
									<em></em>
								</div>

							</div>
							<div class="coupon_down" v-if="couponShow" @click.stop="couponShow = true">
								<div class="coupon_down_item" v-for="(couponItem2, couponIndex2) in couponList.data.list"
									:key="couponIndex2">
									<div class="coupon_down_left">
										<p class="p1">{{ couponItem2.couponContent }}</p>
										<p class="p2">{{ couponItem2.couponTypeValue }} </p>
										<p class="p3">{{ couponItem2.description }}</p>
										<p class="p4">
											{{
												L['有效期至']
											}}
											{{ couponItem2.effectiveStart ? $formatDate(couponItem2.effectiveStart) :
												$formatDate(couponItem2.publishStartTime) }}~{{ couponItem2.effectiveEnd ?
												$formatDate(couponItem2.effectiveEnd) :
												$formatDate(couponItem2.publishEndTime)
											}}
										</p>
									</div>
									<div class="coupon_down_right">
										<div class="p1">
											<!-- <span class="s2">50</span>
											<span class="s1">₸</span> -->

											<div v-if="couponItem2.couponType == 1" class="price ">
												<span class="s2">{{ $formatMoney(couponItem2.publishValue) }} </span>
												<span class="s1"> ₸</span>
											</div>
											<div v-if="couponItem2.couponType == 2" class="count ">
												<span class="s2">{{ couponItem2.publishValue }}</span>
												<span class="s1">{{ L['折'] }}</span>
											</div>
											<div v-if="couponItem2.couponType == 3" class="random ">
												<span class="s2">{{ couponItem2.couponContent }}</span>
											</div>
										</div>

										<div :class="{ p2: true, on: couponItem2.receivedState != 1 && couponItem2.isReceive != 1 }"
											v-if="(couponItem2.robbedRate >= 0 && couponItem2.robbedRate != 100) || (couponItem2.receivePercent >= 0 && couponItem2.receivePercent != 100)"
											@click.stop="couponItem2.receivedState == 1 || couponItem2.isReceive == 1 ? receive(couponItem2.couponId) : ''">
											{{ couponItem2.receivedState == 1 || couponItem2.isReceive == 1 ? L['立即领取']
												:
												L['已领取'] }}
										</div>

										<div class="no_have p2 on"
											v-if="(couponItem2.robbedRate == 100 || couponItem2.receivePercent == 100)">
											{{ L['已抢光'] }}
										</div>

									</div>
								</div>



							</div>

						</div>
						<!-- 颜色分类 -->
						<div v-show="false" class="color_con">
							<div class="tit">
								颜色分类：
							</div>
							<div class="color_list">
								<div class="color_item">
									红
								</div>
								<div class="color_item">
									红
								</div>
								<div class="color_item">
									红
								</div>
								<div class="color_item">
									红
								</div>
								<div class="color_item">
									红
								</div>
								<div class="color_item">
									红
								</div>
								<div class="color_item">
									红
								</div>
								<div class="color_item">
									红
								</div>
								<div class="color_item">
									红
								</div>
								<div class="color_item">
									红
								</div>

								<div class="color_item">
									红
								</div>
								<div class="color_item">
									红
								</div>
							</div>
						</div>
						<!-- 图片规格 -->
						<div class="option_con" v-for="(specItem, specIndex) in goodsDetail.data.specs" :key="specIndex">
							<div class="tit">
								{{ specItem.specName }}:
							</div>
							<div class="option_list">
								<!-- class="option_item_con"  -->
								<!-- checkState : 1-选中，2-可选，3-禁用 -->
								<div :class="{
									option_item_con: true,
									on: sepcValItem.checkState == '1',
									disable: sepcValItem.checkState == '3'
								}" v-for="(sepcValItem, specValIndex) in specItem.specValueList" :key="specValIndex"
									@click="selectSpecVal('choice', specItem.specId, sepcValItem.specValueId, sepcValItem.checkState)"
									v-show="sepcValItem.checkState != '3'">
									<!-- <img src="https://img.easybuykz.com/b2b2c/images/seller/goods/RVMggPjksmMmAND4krD.jpg?bindId=337"
										alt=""> -->

									<div class="option_item " v-show="goodsImgItem" :style="{
										backgroundImage: 'url(' + goodsImgItem + ')'
									}"></div>
									<div class="option_item " :style="{
										backgroundImage: 'url(' + sepcValItem.image + ')'
									}" :title="sepcValItem.specValue" v-if="sepcValItem.image"></div>
									<span class="specval_pre_text " v-else :title="sepcValItem.specValue">{{
										sepcValItem.specValue
									}}</span>
								</div>
								<!-- <div class="option_item on">
									<img src="https://img.easybuykz.com/b2b2c/images/seller/goods/RVMggPjksmMmAND4krD.jpg?bindId=337"
										alt="">
									<p>1</p>
								</div> -->
							</div>
						</div>
						<!-- 规格名称 -->
						<!-- <div class="option_con2">
							<div class="tit">
								规格名称：
							</div>
							<div class="option_list">
								<div class="option_item">
									规格 1
								</div>
								<div class="option_item on">
									规格 1
								</div>
								<div class="option_item disable">
									规格 1
								</div>
							</div>
						</div> -->
						<!-- 数量 -->
						<div class="number">
							<div class="tit">
								{{ L['数量'] }}:
							</div>
							<div class="quantity_edit ">
								<div class="num_reduce">
									<span @click="editNum('reduce')" ref="reduce" class="sp">
										<img src="@/assets/minus.png" alt="">
									</span>
								</div>
								<input class="num_con" type="number" v-model="currentSpecNum"
									@input="editNum('edit', cartItem, $event)" @blur="editNum('blur', $event)" />
								<div class="num_add">
									<span @click="editNum('add')" ref="add" class="sp">
										<img src="@/assets/add.png" alt="">
									</span>
								</div>
							</div>
						</div>

						<!-- 购买 -->
						<div class="buy_con">
							<div class="buy_item" @click="goBuy">
								{{
									L['立即购买']
								}}
							</div>
							<div class="buy_item2" @click="addCart" v-if="goodsDetail.data.isVirtualGoods == 1">
								{{
									L['加入购物车']
								}}
							</div>
						</div>
						<!-- 运费 -->
						<div class="freight_con">
							<img src="@/assets/Shipping.png" alt="">
							<span v-if="goodsDetail.data &&
									goodsDetail.data.deliverInfo &&
									goodsDetail.data.deliverInfo.expressFee
									">
								{{ $formatMoney(goodsDetail.data.deliverInfo.expressFee) }}₸
							</span>
							<span v-else>{{ L['免运费'] }}</span>

						</div>
						<!-- 物流 -->
						<div class="logistics_con">
							<span class="name">{{ L['快递物流'] }}：</span>
							<span class="name2">
								<img src="@/assets/Ellipse1911.png" alt="">
								KazPost
							</span>
						</div>
						<div class="agreement_con"
							v-if="goodsDetail.data.serviceLabels && goodsDetail.data.serviceLabels.length > 0">
							<div class="tit">
								dzon
								{{
									L['保护您放心购物']
								}}
							</div>
							<div class="tit2" @click="agreementShow = true">
								<img src="@/assets/security.png" alt="">
								<span>
									{{
										L['购物安全']
									}}
								</span>
								<img src="@/assets/right3.png" alt="">
							</div>
							<div class="desc_list">
								<div class="desc_item" @click="agreementShow = true"
									v-for="(serverItem, serverIndex) in goodsDetail.data.serviceLabels" :key="serverIndex">
									<i></i>
									<span>
										{{ serverItem.labelName }}
									</span>
								</div>

							</div>

							<div class="desc_list_pop" v-show="agreementShow">
								<div class="desc_close" @click="agreementShow = false">
									<img src="@/assets/close4.png" alt="">
								</div>
								<div v-if="goodsDetail.data.serviceLabels && goodsDetail.data.serviceLabels.length > 0">
									<div class="desc_list_item"
										v-for="(serverItem, serverIndex) in goodsDetail.data.serviceLabels"
										:key="serverIndex">
										<div class="desc_list_pop_tit">
											{{ serverItem.labelName }}
										</div>
										<div class="desc_list_pop_desc">
											{{ serverItem.description }}
										</div>
									</div>

								</div>
							</div>
						</div>
						<!-- 参数 -->
						<div class="parameter_con">
							<div class="tit">
								{{
									L['产品参数']
								}}
							</div>
							<div class="parameter_list" v-if="goodsDetail.data.goodsParameterList">

								<div class="parameter_item" v-for="(parameterItem, paramterIndex) in goodsDetail
									.data.goodsParameterList" :key="paramterIndex" v-show="paramterIndex < 10">
									<span>{{ parameterItem.parameterName }}:</span>
									<span>{{ parameterItem.parameterValue }}</span>
								</div>

								<div class="parameter_item" v-for="(parameterItem, paramterIndex) in goodsDetail
									.data.goodsParameterList.slice(10)" :key="paramterIndex" v-show="goodsParameterShow">
									<span>{{ parameterItem.parameterName }}:</span>
									<span>{{ parameterItem.parameterValue }}</span>
								</div>

							</div>

							<div v-if="moreShow && goodsDetail.data.goodsParameterList" @click="goodsParameterMore">
								<div class="more" v-show="goodsDetail.data.goodsParameterList.length > 10">
									<span>{{ L['查看更多规格'] }}</span>
									<img src="@/assets/down3.png" alt="">
								</div>
							</div>

						</div>

					</div>

					<div class="outer" v-if="goodsDetail.data.storeInf">
						<span :class="goodsDetail.data.storeInf.isFollowStore ? 'follow on' : 'follow'"
							@click="focusStore"></span>
					</div>

				</div>
				<!-- 商品推荐 -->
				<div class="recommend_con">
					<div class="recommend_tit">
						{{ L['为您推荐'] }}
					</div>
					<div class="recommend_list">
						<div class="recommend_item" v-for="(recommendItem,
							recommendIndex) in recommendeList.data" :key="recommendIndex"
							@click="goGoodsDetail(recommendItem.defaultProductId)">
							<img :src="recommendItem.goodsImage" :title="recommendItem.goodsName">
							<p class="price">
								<span>
									<span class="money_number"> {{ $formatMoney(recommendItem.goodsPrice) }} {{ L['₸'] }}
									</span>
								</span>
								<i>
									{{ $formatMoney(recommendItem.marketPrice) }} {{ L['₸'] }}
								</i>
							</p>
							<p class="title">
								<a href="javascript:void(0)">{{ recommendItem.goodsName }}</a>
							</p>
							<div class="ziying" v-if="recommendItem.isOwnShop == 1">
								<!-- <img class="ziying_bg_02" src="../assets/shop/sld_bg.png" alt="" srcset="" /> -->
								<div class="triangle"></div>
								<div class="txt">
									<span> {{ L['自营'] }}</span>
								</div>
								<div class="triangle2"></div>
							</div>
							<!-- <div class="jia">
								<img src="@/assets/add_shopping_cart.png" alt="">
								加入购物车
							</div> -->
						</div>

					</div>
				</div>

			</div>
			<SldLoginModal :visibleFlag="loginModalVisibleFlag" @closeLoingModal="closeLoingModal"
				@refreshInfo="refreshInfo" />
			<el-dialog v-model="imgVisible" width="70%" lock-sroll="false">

				<div style="height:700px;">
					<img :src="imgSource" alt="" class="imgOpen" />
				</div>
			</el-dialog>
		</div>

		<!-- 轮播弹出框 -->
		<div class="lunbo_con" v-show="lunboShow">
			<img class="lunbo_close" @click="lunboShow = false" src="@/assets/close_rounded.png" alt="">

			<div class="lunbo_left">
				<div class="show_box" ref="show_box2">
					<div class="show_item"
						v-if="goodsDetail.data.defaultProduct && goodsDetail.data.defaultProduct.goodsPics && goodsDetail.data.defaultProduct.goodsPics.length > 0">
						<li :class="{ goods_img: true, on: currentDefaultImage == goodsImgIndex }" v-for="(goodsImgItem, goodsImgIndex) in goodsDetail.data
							.defaultProduct.goodsPics" :key="goodsImgIndex" @click="selectDefaultImage(goodsImgItem, goodsImgIndex)">
							<div class="goods_image" :style="{
								backgroundImage: 'url(' + goodsImgItem + ')'
							}">
								<img v-if="goodsImgIndex == 0 && goodsDetail.data.goodsVideo" class="icon_play"
									src="@/assets/play_button1.png" alt="">
							</div>
						</li>
					</div>
				</div>
			</div>

			<div class="lunbo_right">
				<div class="lunbo_right_item">
					<span class="arrow_left" @click="switchDefaultImage('left')">
						<img src="@/assets/arrow_left_alt2.png" alt="">
					</span>
					<div class="goods_img">
						<!-- <img src="https://test-img.easybuykz.com/b2b2cdev/images/seller/goods/bNpSKX7KlfPF1kMLOVu.png?bindId=17"
							alt=""> -->

						<div class="imageBorder" :class="{
							default_image: true,
							skeleton_default_image: firstLoading
						}" :style="{ backgroundImage: 'url(' + defaultImage + ')' }">
							<video v-if="currentDefaultImageShow == true && goodsDetail.data.goodsVideo
								" controls playsinline="playsinline" class="imageBorder default_image" :poster="defaultImage" autoplay
								ref="video">
								<source :src="goodsDetail.data.goodsVideo" type="video/mp4" />
							</video>
						</div>
						<div v-if="currentDefaultImageShow == false && currentDefaultImage == 0 && goodsDetail.data.goodsVideo"
							class="icon_play_con">
							<img @click="playV" class="icon_play" src="@/assets/play_button1.png" alt="">
						</div>
					</div>
					<span class="arrow_right" @click="switchDefaultImage('right')">
						<img src="@/assets/arrow_right_alt2.png" alt="">
					</span>
				</div>

				<div class="lunbo_buy" @click="goBuy">
					{{ L['立即购买'] }}
				</div>

			</div>

		</div>

	</div>
</template>

<script>
import SldHomeTopSearch from '../../components/SldHomeTopSearch'
import NavCatHeader from '../../components/NavCatHeader'
import addrDataRu from '@/assets/area.json'
import addrDataCn from '@/assets/area_cn.json'
import {
	reactive,
	getCurrentInstance,
	ref,
	onMounted,
	watch,
	computed,
	nextTick
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ElMessage, ElRate } from 'element-plus'
import { qrcanvas } from 'qrcanvas'
import StoreHeaderCat from '../store/StoreHeaderCat'
import CouponItem from '@/components/CouponItem'
import { useStore } from 'vuex'
import SldLoginModal from '../../components/loginModal'
import SldCommonEmpty from '../../components/SldCommonEmpty'
export default {
	name: 'goods-detail',
	components: {
		ElRate,
		StoreHeaderCat,
		SldHomeTopSearch,
		NavCatHeader,
		CouponItem,
		SldLoginModal,
		SldCommonEmpty
	},
	setup() {
		const firstLoading = ref(true) //是否第一次加载
		const router = useRouter()
		const route = useRoute()
		const store = useStore()
		const imgVisible = ref(false)
		const imgSource = ref('')
		const imgIndex = ref(-1)
		const vid = ref(0) //店铺id
		const { proxy } = getCurrentInstance()
		const L = proxy.$getCurLanguage()
		const goodsDetail = reactive({ data: {} }) //商品详情数据
		const isChoice = ref('default') //是默认选中的,还是点击选择规格之后的  default:默认  choice:选择
		const productId = ref('') //货品id
		const fullDisList = reactive({ data: [] }) //满优惠促销列表
		const couponList = reactive({ data: [] }) //获取店铺优惠券列表
		const pictureBig = ref(null) //大图的信息
		const maskBox = ref(null) //遮罩盒子的信息
		const maskShow = ref(false) //遮罩是否显示
		const previewBox = ref(null) // 左侧主图元素信息
		const zoomBox = ref(null) //左侧主图的父元素的信息
		const defaultImage = ref('') //默认主图路径
		const currentDefaultImage = ref('0') //默认主图显示第一张
		const currentSpecNum = ref(1) //商品编辑数量，默认数量为1
		const recommendeList = reactive({ data: [] }) //看了又看商品
		const storePopularList = reactive({ data: [] }) //店铺推荐及热门收藏
		const storePopularType = ref('recommend') //店铺推荐及热门收藏,默认显示店铺推荐
		const goodsDesctionType = ref('detail') //商品详情,评价,商品服务,店铺热销,默认显示商品详情
		const goodsCommentsInfo = reactive({ data: {} }) //商品评价信息
		const evaluationType = ref('') //商品评价类型,默认显示全部
		const recommendedList = reactive({ data: [] }) //店铺推荐列表
		const evaluationCurrent = ref(1) //评价列表默认第一页
		const evaluationPageSize = ref(5) //评价列表默认一页显示5条数据
		const salesCurrent = ref(1) //店铺热销列表默认一页
		const salesPageSize = ref(20) //店铺热销列表默认一页显示20条数据
		const recommendeData = reactive({ data: {} }) //店铺热销推荐数据
		const couponModel = ref(false) //优惠券弹框是否显示
		const cartListData = reactive({ data: store.state.cartListData }) //获取vux的store中的购物车数据
		const loginModalVisibleFlag = ref(false) //登录弹框是否显示，默认不显示
		const container = ref(null) // 商品详情页底部内容区
		const containerTop = ref(0) //商品详情页底部内容区的top值
		const containCon = ref(false) //固定内容区头部
		const fullDiscountModel = ref(false) //满优惠弹框是否显示
		const goodsPictureList = ref(null) //商品图片列表
		const curCouponPage = ref(1) //当前为第一页优惠券
		const couponPageSize = ref(6) //优惠券默认一页显示6条数据
		const wxShareCode = ref(false) //微信分享二维码是否显示
		const parameterShow = ref(false) //规格参数查看是否查看全部，默认为否
		const score = ref(0) //好评率
		const colors = ref(['#FDCC0D', '#FDCC0D', '#FDCC0D']) //星星颜色
		const curAddr = ref(-1)
		const curAddrName = ref('')
		const addrIdx = ref(0)
		const otherAddrIdx = ref(0)
		const othTopIdx = reactive({
			0: 0,
			1: 0,
			2: 0
		})

		var addrData = localStorage.getItem('languageSet') == 'zh' ? addrDataCn : addrDataRu
		const otherTree = ref([
			addrData[othTopIdx['0']],
			addrData[othTopIdx['0']].children[othTopIdx['1']]
		])
		const othAddrDe = ref(addrData)
		const addrDialogVisible = ref(false)
		const logFlag = ref(store.state.loginFlag)
		// 促销活动信息
		const preSellInfo = reactive({ data: {} })
		const pinInfo = reactive({ data: {} })
		const seckillInfo = reactive({ data: {} })
		const ladderInfo = reactive({ data: {} })
		const address_list = reactive({ data: [] })
		const isShowQr = ref(false)
		const secInt = ref('')
		const time = reactive({
			day: '00',
			hours: '00',
			minutes: '00',
			seconds: '00'
		})
		const judgeStock = computed(() => {
			return (
				goodsDetail.data.defaultProduct.productStock == 0 ||
				(JSON.stringify(preSellInfo.data) != '{}' &&
					preSellInfo.data.presellStock == 0) ||
				(JSON.stringify(pinInfo.data) != '{}' &&
					pinInfo.data.spellStock == 0) ||
				(JSON.stringify(seckillInfo.data) != '{}' &&
					seckillInfo.data.seckillStock == 0)
			)
		})

		const agreementShow = ref(false);
		const goodsParameterShow = ref(false);
		const moreShow = ref(true);

		const regionCode = ref(0)
		// 促销活动信息end

		const storeData = reactive({ cat: [], info: {} }) //店铺数据，cat：店铺分类，info：店铺基本信息

		// 首页商品图控制滑动
		const show_box1 = ref([]);
		// 控制滑动时内容不被点击
		// const adv_05_pointer = ref(1)
		const control = reactive({
			isDown: false, // 是否按下鼠标
			startY: 0, // 鼠标起始位置
			scrollTop: 0 // 滚动条位置
		});
		// 弹框商品图滚动
		const show_box2 = ref([]);
		// 控制滑动时内容不被点击
		// const adv_05_pointer = ref(1)
		const control2 = reactive({
			isDown: false, // 是否按下鼠标
			startY: 0, // 鼠标起始位置
			scrollTop: 0 // 滚动条位置
		});

		onMounted(() => {

			// 首页商品图控制滑动
			nextTick(() => {
				show_box1.value.addEventListener('mousedown', (e) => {
					control.isDown = true;
					control.startY = e.pageY - show_box1.value.offsetTop;
					control.scrollTop = show_box1.value.scrollTop;
				});
				show_box1.value.addEventListener('mouseup', (e) => {
					control.isDown = false;
					// adv_05_pointer.value = 1;
				});
				show_box1.value.addEventListener('mousemove', (e) => {
					if (!control.isDown) return;
					e.preventDefault();
					// adv_05_pointer.value = 2;
					const Y = e.pageY - show_box1.value.offsetTop;
					const walk = (Y - control.startY) * 2; // 滑动距离
					show_box1.value.scrollTop = control.scrollTop - walk;
				});
			})


			// 弹框商品图滚动
			show_box2.value.addEventListener('mousedown', (e) => {
				control2.isDown = true;
				control2.startY = e.pageY - show_box2.value.offsetTop;
				control2.scrollTop = show_box2.value.scrollTop;
			});
			show_box2.value.addEventListener('mouseup', (e) => {
				control2.isDown = false;
				// adv_05_pointer.value = 1;
			});
			show_box2.value.addEventListener('mousemove', (e) => {
				if (!control2.isDown) return;
				e.preventDefault();
				// adv_05_pointer.value = 2;
				const Y = e.pageY - show_box2.value.offsetTop;
				const walk = (Y - control2.startY) * 2; // 滑动距离
				show_box2.value.scrollTop = control2.scrollTop - walk;
			});


			getInitDataStatic(route.query.productId)
			productId.value = route.query.productId
			// containerTop.value = offset(container.value).top
			// window.addEventListener('scroll', scrollHandle) //绑定页面滚动事件
			// window.addEventListener('click', () => {
			// 	addrDialogVisible.value = false
			// })
			if (!logFlag.value) {
				addrIdx.value = 1
			}

		})

		//获取店铺基本信息
		const getStoreInfoBaseInfo = () => {
			proxy
				.$get('v3/seller/front/store/detail', { storeId: vid.value })
				.then(res => {
					if (res.state == 200) {
						storeData.info = res.data
						storeData.info.storeAverageScore = (
							(res.data.deliverScore * 1 +
								res.data.descriptionScore * 1 +
								res.data.serviceScore * 1) /
							3
						).toFixed(1)
					}
				})
		}
		//获取店铺分类数据
		const getStoreCatData = () => {
			proxy
				.$get('v3/seller/front/store/storeCategory', { storeId: vid.value })
				.then(res => {
					if (res.state == 200) {
						storeData.cat = res.data
					}
				})
		}

		const scrollHandle = async e => {
			let elementScrollTop = e.srcElement.scrollingElement.scrollTop //获取页面滚动高度
			if (
				document.getElementById('container') &&
				elementScrollTop > document.getElementById('container').offsetTop
			) {
				containCon.value = true
				await proxy.$nextTick()
				getCode()

				console.log(
					goodsDetail.data.qrCodeImage,
					'goodsDetail.data.qrCodeImage'
				)

				if (goodsDetail.data.qrCodeImage) {
					handleQrImage(goodsDetail.data.qrCodeImage, 'top')
				} else {
					// goodsDetail.data.shareLink = goodsDetail.data.shareLink  + '&languageSet=' + (localStorage.getItem("languageSet") || "ru")
					getBigcode(goodsDetail.data.shareLink, 'top')
				}
			} else {
				// goodsDetail.data.shareLink = goodsDetail.data.shareLink  + '&languageSet=' + (localStorage.getItem("languageSet") || "ru")
				getBigcode(goodsDetail.data.shareLink)
				containCon.value = false
			}
		}
		const currentDefaultImageShow = ref(false)
		// 点击播放视频
		const playV = () => {
			// currentDefaultImage.value = -1
			currentDefaultImageShow.value = true
			defaultImage.value = ''
			maskShow.value = false
			videoEnd()
		}

		//获取商品详情数据
		const getInitDataStatic = proId => {
			let params = {
				productId: proId
			}
			proxy
				.$get('v3/goods/front/goods/details', params)
				.then(res => {
					if (res.state == 200) {
						let staticData = [
							'brandId',
							'brandName',
							'categoryPath',
							'categoryIdPath',
							'goodsBrief',
							'goodsDetails',
							'goodsBrief',
							'goodsId',
							'goodsName',
							'goodsParameterList',
							'goodsVideo',
							'topTemplateContent',
							'bottomTemplateContent',
							'serviceLabels',
							'qrCodeImage'
						]

						staticData.forEach(item => {
							if (item == 'categoryPath') {
								goodsDetail.data.categoryPath = res.data.categoryPath.split(
									'->'
								)
							} else if (item == 'categoryIdPath') {
								goodsDetail.data.categoryIdPath = [res.data.categoryId1, res.data.categoryId2, res.data.categoryId3]
							} else if (item == 'goodsName' && res.data[item]) {
								goodsDetail.data[item] = res.data[item]
								document.title = res.data[item]
							} else {
								goodsDetail.data[item] = res.data[item]
							}
						})
						if (goodsDetail.data.topTemplateContent) {
							goodsDetail.data.topTemplateContent = proxy.$quillEscapeToHtml(
								goodsDetail.data.topTemplateContent
							)
							//处理背景样式
							if (
								goodsDetail.data.topTemplateContent.indexOf(
									'<p style="display:none;" data-background="'
								) != -1
							) {
								let bg = goodsDetail.data.topTemplateContent
									.split('<p style="display:none;" data-background="')[1]
									.split('">')[0]
								goodsDetail.data.topTemplateContentBg = bg
							}
						}
						if (goodsDetail.data.goodsDetails) {
							goodsDetail.data.goodsDetails = proxy.$quillEscapeToHtml(
								goodsDetail.data.goodsDetails
							)
							//处理背景样式
							if (
								goodsDetail.data.goodsDetails.indexOf(
									'<p style="display:none;" data-background="'
								) != -1
							) {
								let bg = goodsDetail.data.goodsDetails
									.split('<p style="display:none;" data-background="')[1]
									.split('">')[0]
								goodsDetail.data.goodsDetailsBg = bg
							}
						}
						if (goodsDetail.data.bottomTemplateContent) {
							goodsDetail.data.bottomTemplateContent = proxy.$quillEscapeToHtml(
								goodsDetail.data.bottomTemplateContent
							)
							//处理背景样式
							if (
								goodsDetail.data.bottomTemplateContent.indexOf(
									'<p style="display:none;" data-background="'
								) != -1
							) {
								let bg = goodsDetail.data.bottomTemplateContent
									.split('<p style="display:none;" data-background="')[1]
									.split('">')[0]
								goodsDetail.data.bottomTemplateContentBg = bg
							}
						}
						currentDefaultImage.value = 0
						vid.value = res.data.storeInf.storeId
						proxy.$sldStatEvent({
							behaviorType: 'gpv',
							goodsId: goodsDetail.data.goodsId,
							storeId: vid.value
						})
					} else {
						ElMessage.error(res.msg)
					}
				})
				.then(() => {
					getInitDataDynamic(productId.value)
					getRecommend()
					getStorePopular()
					getCouponList()
					getFullDiscountList()
					getEvaluation()
					addLog()
					getAddressList()
					getStoreInfoBaseInfo()
					getStoreCatData()
					if (goodsDetail.data.qrCodeImage) {
						handleQrImage(goodsDetail.data.qrCodeImage)
					} else {
						// goodsDetail.data.shareLink = goodsDetail.data.shareLink  + '&languageSet=' + (localStorage.getItem("languageSet") || "ru")
						getBigcode(goodsDetail.data.shareLink)
					}
				})
		}

		const getInitDataDynamic = (proId) => {
			let params = {
				productId: proId
			}
			proxy
				.$get('v3/goods/front/goods/details2', params)
				.then(res => {
					if (res.state == 200) {
						// 临时需求，第一位的图片作为视频封面
						res.data.defaultProduct.goodsPics.unshift(res.data.defaultProduct.goodsPics[0])
						defaultImage.value = res.data.defaultProduct.goodsPics[0]
						let dynamicData = [
							'defaultProduct',
							'deliverInfo',
							'effectSpecValueIds',
							'followGoods',
							'specs',
							'storeInf',
							'sales',
							'state',
							'shareLink',
							'shareImage',
							'isVirtualGoods'
						]
						dynamicData.forEach(item => {
							goodsDetail.data[item] = res.data[item]
						})

						if (goodsDetail.data.state != 3) {
							getRecom()
						}
						if (goodsDetail.data.defaultProduct.promotionType == 103) {
							getPreSell(res.data.defaultProduct.productId)
						} else if (goodsDetail.data.defaultProduct.promotionType == 102) {
							getPin()
						} else if (goodsDetail.data.defaultProduct.promotionType == 104) {
							getSeckill()
						} else if (goodsDetail.data.defaultProduct.promotionType == 105) {
							getLadder()
						} else {
							preSellInfo.data = {}
							pinInfo.data = {}
							seckillInfo.data = {}
							ladderInfo.data = {}
						}
						firstLoading.value = false
					}
				})
				.then(() => {
					getCode()
				})
		}
		const handleQrImage = (qrCodeImage, type) => {
			console.log('qrCodeImage', qrCodeImage)
			let img = document.createElement('img')
			img.src = `data:image/png;base64,${qrCodeImage}`
			img.width = 145
			img.height = 145

			if (type == 'top') {
				if (document.getElementById('qrcodeBig1')) {
					document.getElementById('qrcodeBig1').innerHTML = ''
					document.getElementById('qrcodeBig1').appendChild(img)
				}
			} else {
				if (document.getElementById('qrcodeBig')) {
					document.getElementById('qrcodeBig').innerHTML = ''
					document.getElementById('qrcodeBig').appendChild(img)
				}
			}
		}

		//视频播放结束时触发
		const videoEnd = () => {
			proxy.$nextTick(() => {
				proxy.$refs.video.onended = () => {
					currentDefaultImage.value = 0
					defaultImage.value = goodsDetail.data.defaultProduct.goodsPics[0]
				}
			})
		}

		// 促销活动信息
		const getPreSell = productId => {
			let param = {
				productId: productId,
				promotionId: goodsDetail.data.defaultProduct.promotionId
			}

			proxy.$get('v3/promotion/front/preSell/detail', param).then(res => {
				if (res.state == 200) {
					let now = new Date()
					let preStartDate = new Date(res.data.startTime)
					let preEndDate = new Date(res.data.endTime)
					preSellInfo.data = res.data
					let countTime = 0
					preSellInfo.data.endTime = formatPreTime(new Date(res.data.endTime))
					preSellInfo.data.startTime = formatPreTime(
						new Date(res.data.startTime)
					)
					if (now > preStartDate && now < preEndDate) {
						preSellInfo.data.pre_run = 2 //活动进行中
						countTime = res.data.distanceEndTime
						countDown(countTime)
					} else if (now < preStartDate) {
						preSellInfo.data.pre_run = 1 //活动未开始
						countTime =
							(new Date(res.data.startTime).getTime() - now.getTime()) / 1000
						countDown(countTime)
					} else if (now > preEndDate) {
						preSellInfo.data.pre_run = 3 //活动已结束
					}

					genQrcode()
				} else {
					ElMessage.error(res.msg)
				}
			})
		}

		const getPin = () => {
			let param = {
				productId: goodsDetail.data.defaultProduct.productId,
				promotionId: goodsDetail.data.defaultProduct.promotionId
			}

			proxy.$get('v3/promotion/front/spell/detail', param).then(res => {
				if (res.state == 200) {
					pinInfo.data = res.data
					let countTime = 0
					let now = new Date()
					let startTime = new Date(res.data.startTime)
					if (now < startTime) {
						countTime = (startTime.getTime() - now.getTime()) / 1000
						countDown(countTime)
					} else {
						countTime = res.data.distanceEndTime
						countDown(countTime)
					}
					genQrcode()
				} else {
					ElMessage.error(res.msg)
				}
			})
		}

		const getSeckill = () => {
			let param = {
				productId: goodsDetail.data.defaultProduct.productId,
				promotionId: goodsDetail.data.defaultProduct.promotionId
			}
			proxy.$get('v3/promotion/front/seckill/detail', param).then(res => {
				if (res.state == 200) {
					seckillInfo.data = res.data
					let now = new Date()
					let countTime = 0
					let startTime = new Date(res.data.startTime)

					if (seckillInfo.data.state == 1 || seckillInfo.data.state == 2) {
						countTime = res.data.distanceEndTime
						countDown(countTime)
					} else {
						countTime = startTime.getTime() - now.getTime()
					}
					genQrcode()
				}
			})
		}

		const countDown = countTime => {
			secInt.value = setInterval(() => {
				if (countTime == 0) {
					getInitDataDynamic(route.query.productId)
					clearInterval(secInt.value)
				} else {
					countTime--
					let day = parseInt(countTime / 60 / 60 / 24)
					let hours = parseInt((countTime / 60 / 60) % 24)
					let minutes = parseInt((countTime / 60) % 60)
					let seconds = parseInt(countTime % 60)
					time.day = day
					time.hours = hours > 9 ? hours : '0' + hours
					time.minutes = minutes > 9 ? minutes : '0' + minutes
					time.seconds = seconds > 9 ? seconds : '0' + seconds
				}
			}, 1000)
		}

		const formatPreTime = time => {
			let op = new Date(time)
			let year = op.getFullYear()
			let month = op.getMonth() + 1
			let day = op.getDate()
			let hour = op.getHours()
			let minute = op.getMinutes()
			let part1 = [year, month, day]
				.map(i => (i.toString().length < 2 ? `0${i}` : i))
				.join('-')
			let part2 = [hour, minute]
				.map(i => (i.toString().length < 2 ? `0${i}` : i))
				.join(':')
			return part1 + ' ' + part2
		}

		const sorcRemind = () => {

			if (!store.state.loginFlag) {
				loginModalVisibleFlag.value = true
				return
			}

			let param = {
				stageProductId: seckillInfo.data.stageProductId
			}

			proxy.$post('v3/promotion/front/seckill/isRemind', param).then(res => {
				if (res.state == 200) {
					ElMessage.success(res.msg)
					seckillInfo.data.isRemind = !seckillInfo.data.isRemind
				}
			})
		}

		const getLadder = () => {
			let param = {
				productId: goodsDetail.data.defaultProduct.productId,
				promotionId: goodsDetail.data.defaultProduct.promotionId
			}
			proxy.$get('v3/promotion/front/ladder/group/detail', param).then(res => {
				if (res.state == 200) {
					ladderInfo.data = res.data
					let now = new Date()
					let countTime = 0
					let startTime = new Date(res.data.startTime)
					if (now < startTime) {
						countTime = (startTime.getTime() - now.getTime()) / 1000
						countDown(countTime)
						ladderInfo.data.state = 1
					} else {
						countTime = res.data.distanceEndTime
						countDown(countTime)
						ladderInfo.data.state = 2
					}
					genQrcode()
				}
			})
		}

		const genQrcode = () => {
			if (judgeStock.value) {
				return
			}
			proxy.$nextTick(() => {
				let canvas
				if (goodsDetail.data.qrCodeImage) {
					canvas = document.createElement('img')
					canvas.src = `data:image/png;base64,${goodsDetail.data.qrCodeImage}`
					canvas.width = 135
					canvas.height = 135
				} else {
					canvas = qrcanvas({
						data: goodsDetail.data.shareLink, //二维码内容
						size: 125,
						colorDark: 'red'
					})
				}
				setTimeout(() => {
					document.getElementById('qrcodeAct').innerHTML = ''
					document.getElementById('qrcodeAct').appendChild(canvas)
				}, 10)
			})
		}

		// 促销活动信息end

		const recomOffShop = reactive({ data: [] })
		const getRecom = () => {
			proxy
				.$get('v3/goods/front/goods/goodsList', {
					storeId: goodsDetail.data.categoryId1
				})
				.then(res => {
					if (res.state == 200) {
						let top = Math.floor(Math.random() * (res.data.list.length - 8)) + 8
						let end = top - 8
						recomOffShop.data = res.data.list
							.filter(
								item =>
									item.defaultProductId !=
									goodsDetail.data.defaultProduct.productId
							)
							.slice(end, top)
					}
				})
		}

		//添加足迹
		const addLog = () => {
			let params = {
				productId: productId.value
			}
			proxy.$post('v3/member/front/productLookLog/add', params).then(res => {
				if (res.state == 200) {
					console.log()
				}
			})
		}
		//跳转去商品详情页
		const goGoodsDetail = productId => {
			proxy.$goGoodsDetail(productId)
		}

		//获取二维码
		const getCode = () => {
			proxy.$nextTick(() => {
				let canvas = qrcanvas({
					data: L['下单'], //二维码内容
					size: 20,
					colorDark: 'red'
				})
				if (document.getElementById('qrcode')) {
					document.getElementById('qrcode').innerHTML = ''
					document.getElementById('qrcode').appendChild(canvas)
				}
				if (document.getElementById('qrcode1')) {
					document.getElementById('qrcode1').innerHTML = ''
					document.getElementById('qrcode1').appendChild(canvas)
				}
			})
		}

		//获取商品的shareLink二维码
		const getBigcode = (link, type) => {
			if (link) {
				link = link + '&languageSet=' + (localStorage.getItem("languageSet") || "ru")
			}
			proxy.$nextTick(() => {
				let canvas = qrcanvas({
					data: link, //二维码内容
					size: 125,
					colorDark: 'red'
				})
				if (type == 'top') {
					if (document.getElementById('qrcodeBig1')) {
						document.getElementById('qrcodeBig1').innerHTML = ''
						document.getElementById('qrcodeBig1').appendChild(canvas)
					}
				} else {
					if (document.getElementById('qrcodeBig')) {
						document.getElementById('qrcodeBig').innerHTML = ''
						document.getElementById('qrcodeBig').appendChild(canvas)
					}
				}
			})
		}

		//获取店铺优惠券列表
		const getCouponList = () => {
			let params = {
				storeId: vid.value,
				current: curCouponPage.value,
				// pageSize: couponPageSize.value
			}
			proxy
				.$get('v3/promotion/front/coupon/storeCouponList', params)
				.then(res => {
					if (res.state == 200) {
						let result = res.data
						couponList.data = result
					}
				})
		}

		//获取满优惠促销列表
		const getFullDiscountList = () => {
			let params = {
				productId: productId.value
			}
			proxy.$get('v3/goods/front/goods/activityList', params).then(res => {
				if (res.state == 200) {
					let result = res.data
					res.data.map((item) => {
						item.descriptionList = item.descriptionList.map(i =>
							i.replace(/<(.+?)>/g, function (num) {
								return (
									"<text style='color:var(--color_main)'>" +
									num.slice(1, num.length - 1) +
									'</text>'
								)
							})
						)
						item.descriptionList = item.descriptionList.map(i =>
							i.replace(/x[\d]/g, function (num) {
								return "<text style='color:var(--color_main)'>" + num + '</text>'
							})
						)
					})
					fullDisList.data = result
				}
			})
		}
		//查看满优惠
		const lookFullDiscount = () => {
			fullDiscountModel.value = true
		}

		/**
		 * 选择规格值
		 * @param type:类型  值:choice,规格选择  default:默认
		 * @param specId:父级规格值
		 * @param specValueId:点击的当前的规格值
		 */
		const selectSpecVal = (type, specId, specValueId, checkState) => {

			if (checkState == 3) {
				return
			}

			currentDefaultImageShow.value = false

			isChoice.value = type == 'choice' ? 'choice' : 'default'
			let curParSpec = [] //当前点击的规格的父级id的当前项
			curParSpec = goodsDetail.data.specs.filter(item => item.specId == specId)
			let curSPec = [] //当前点击的规格的规格id的当前项
			curSPec = curParSpec[0].specValueList.filter(
				item1 => item1.specValueId == specValueId
			)
			curSPec[0].checkState = 1
			//被选择的规格值的id
			let choiceSpecIds = []
			goodsDetail.data.specs.forEach(item => {
				if (item.specId != specId) {
					item.specValueList.forEach(item1 => {
						if (item1.checkState == '1') {
							// checkState: 1-选中，2-可选，3-禁用
							choiceSpecIds.push(item1.specValueId)
						}
					})
				} else {
					choiceSpecIds.push(specValueId)
				}
			})
			let params = {
				goodsId: goodsDetail.data.goodsId,
				specValueIds: choiceSpecIds.join(',')
			}
			proxy.$get('v3/goods/front/goods/productInfo', params).then(res => {
				if (res.state == 200) {
					let result = res.data
					// 临时需求，第一位的图片作为视频封面
					result.defaultProduct.goodsPics.unshift(result.defaultProduct.goodsPics[0])

					goodsDetail.data.defaultProduct = result.defaultProduct
					productId.value = result.defaultProduct.productId
					goodsDetail.data.specs = result.specs
					defaultImage.value = goodsDetail.data.defaultProduct.goodsPics[0]
					currentDefaultImage.value = 0
					isShowQr.value = false
				}
			})
		}

		//商品数量的编辑
		const editNum = (type, e) => {
			let productStock = goodsDetail.data.defaultProduct.productStock
			if (goodsDetail.data.defaultProduct.productStock == 0) {
				productStock = 99999
			}
			if (type == 'add') {
				if (currentSpecNum.value >= productStock) {
					currentSpecNum.value = productStock
				} else {
					currentSpecNum.value++
					if (currentSpecNum.value > 99999) {
						ElMessage(L['超过购买限制!'])
						currentSpecNum.value = 99999
					}
				}
				getUserEx(regionCode.value)
			} else if (type == 'edit') {
				if (currentSpecNum.value > 99999) {
					ElMessage(L['超过购买限制!'])
					currentSpecNum.value = 99999
				}
				if (currentSpecNum.value != '' && currentSpecNum.value > 0) {
					getUserEx(regionCode.value)
				}

			} else if (type == 'blur') {
				if (currentSpecNum.value >= productStock) {
					currentSpecNum.value = productStock
				} else {
					currentSpecNum.value = e.currentTarget.value
					if (currentSpecNum.value <= 0) {
						currentSpecNum.value = 1
					} else {
						currentSpecNum.value = currentSpecNum.value.replace(/\D/g, '')
						if (currentSpecNum.value > 99999) {
							currentSpecNum.value = 99999
						}
					}
				}
				getUserEx(regionCode.value)

			} else if (type == 'reduce') {
				if (currentSpecNum.value > 1) {
					currentSpecNum.value--
				} else {
					currentSpecNum.value = 1
				}
				getUserEx(regionCode.value)

			}
		}

		//改变数量按钮样式
		const disStyle = reactive({
			//目的是进入商品详情页面就让减按钮呈现禁止状态
			color: '#DDDDDD',
			backgroundColor: '#F8F8F8'
		})
		watch(
			() => currentSpecNum.value,
			() => {
				//监听数量对加和减的样式做出调整
				let productStock = goodsDetail.data.defaultProduct.productStock
				if (goodsDetail.data.defaultProduct.productStock == 0) {
					productStock = 99999
				}

				if (currentSpecNum.value >= productStock) {
					proxy.$refs.add.style.color = '#DDDDDD'
					proxy.$refs.add.style.backgroundColor = '#F8F8F8'
				} else if (currentSpecNum.value <= 1) {
					disStyle.color = '#DDDDDD'
					disStyle.backgroundColor = '#F8F8F8'
				} else {
					disStyle.color = ''
					disStyle.backgroundColor = ''
					proxy.$refs.add.style.color = ''
					proxy.$refs.add.style.backgroundColor = ''
				}
			}
		)

		watch(currentSpecNum, () => {
			if (currentSpecNum.value > goodsDetail.data.defaultProduct.productStock) {
				currentSpecNum.value = goodsDetail.data.defaultProduct.productStock
			}
			let reg = /\./g

			if (
				currentSpecNum.value &&
				(reg.test(currentSpecNum.value) || currentSpecNum.value <= 0)
			) {
				currentSpecNum.value = 1
			}
		})

		//加入购物车
		const addCart = () => {
			if (store.state.loginFlag) {
				let paramsData = {
					productId: productId.value,
					number: currentSpecNum.value
				}
				proxy.$addCart(paramsData)
			} else {
				//未登录提示登录
				loginModalVisibleFlag.value = true
				// let {
				// 	goodsId,
				// 	goodsName,
				// 	specs,
				// 	storeInf: { storeName, storeId },
				// 	defaultProduct: { productPrice, productId, goodsPics, productStock }
				// } = goodsDetail.data

				// let paramsData = {
				// 	number: currentSpecNum.value,
				// 	goodsPics: goodsPics[0],
				// 	goodsId,
				// 	goodsName,
				// 	specs,
				// 	storeName,
				// 	storeId,
				// 	productPrice,
				// 	productId,
				// 	productStock
				// }

				// proxy.$addCart(paramsData)
			}
		}
		//立即购买
		const goBuy = () => {
			if (store.state.loginFlag) {
				let query = {
					orderType: 1,
					goodsId: goodsDetail.data.goodsId,
					productId: goodsDetail.data.defaultProduct.productId,
					numbers: currentSpecNum.value,
					ifcart: false
				}

				if (goodsDetail.data.defaultProduct.promotionType == 102) {
					query.isAloneBuy = true
				}

				router.push({
					path: '/buy/confirm',
					query
				})
				localStorage.setItem('addressId', curAddr.value)
			} else {
				//未登录提示登录
				loginModalVisibleFlag.value = true
			}
		}
		//关闭登录弹框
		const closeLoingModal = () => {
			loginModalVisibleFlag.value = false
		}

		//获取看了又看商品(人气数)
		const getRecommend = () => {
			let params = {
				storeId: vid.value,
				sort: 5,
				pageSize: 3,
				current: 1
			}
			proxy.$get('v3/goods/front/goods/goodsList', params).then(res => {
				if (res.state == 200) {
					let result = res.data
					recommendeList.data = result.list
					recommendeList.data.map(
						item => (item.goodsPrice = new Number(item.goodsPrice).toFixed(2))
					)
				} else {
					ElMessage.error(res.msg)
				}
			})
		}

		//获取店铺推荐的商品(销量数);获取热门收藏的商品(收藏数)  type:recommend销量数  collection:收藏数
		const getStorePopular = type => {
			if (type == '' || !type) {
				type = 'recommend'
			} else {
				storePopularType.value = type
			}
			let params = {
				storeId: vid.value,
				sort:
					storePopularType.value == 'recommend'
						? 1
						: storePopularType.value == 'collection'
							? 6
							: '',
				pageSize: 6,
				current: 1
			}
			proxy.$get('v3/goods/front/goods/goodsList', params).then(res => {
				if (res.state == 200) {
					let result = res.data
					storePopularList.data = result.list
					storePopularList.data.map(
						item => (item.goodsPrice = new Number(item.goodsPrice).toFixed(2))
					)
				} else {
					ElMessage.error(res.msg)
				}
			})
		}

		//切换商品详情,评价,服务,热销
		const goodsDescType = type => {
			let elementScrollTop =
				window.pageYOffset ||
				document.documentElement.scrollTop ||
				document.body.scrollTop //获取页面滚动高度
			if (elementScrollTop > containerTop.value + 33 + 48) {
				window.scrollTo({
					top: containerTop.value,
					behavior: 'smooth'
				})
			}

			if (type == '' || !type) {
				goodsDesctionType.value = 'detail'
			} else {
				goodsDesctionType.value = type
			}
			if (type == 'salestore') {
				getSalestore()
			}
		}

		//获取商品评价
		const getEvaluation = type => {
			if (!type) {
				evaluationType.value = ''
			} else {
				evaluationType.value = type
			}
			let params = {
				productId: productId.value,
				current: evaluationCurrent.value,
				pageSize: evaluationPageSize.value,
				type: evaluationType.value
			}
			proxy.$get('v3/goods/front/goods/comment', params).then(res => {
				if (res.state == 200) {
					let result = res.data
					goodsCommentsInfo.data = result
					score.value = Number(goodsCommentsInfo.data.avgScore)
					goodsCommentsInfo.data &&
						goodsCommentsInfo.data.list &&
						goodsCommentsInfo.data.list.length > 0 &&
						goodsCommentsInfo.data.list.map(commentsItem => {
							commentsItem.memberName =
								commentsItem.memberName.slice(0, 1) +
								'***' +
								commentsItem.memberName.slice(
									commentsItem.memberName.length - 1,
									commentsItem.memberName.length
								)
						})
				} else {
					ElMessage.error(res.msg)
				}
			})
		}
		//评价列表上一页
		const handlePrevCilickChange = () => {
			if (evaluationCurrent.value == 1) {
				evaluationCurrent.value = 1
			} else {
				evaluationCurrent.value--
			}
			getEvaluation(evaluationType.value)
		}
		//评价列表下一页
		const handleNextCilickChange = () => {
			if (evaluationCurrent.value >= goodsCommentsInfo.data.list.length) {
				evaluationCurrent.value = goodsCommentsInfo.data.list.length
			} else {
				evaluationCurrent.value++
			}
			getEvaluation(evaluationType.value)
		}
		//评价列表当前选择页
		const handleCurrentChange = val => {
			evaluationCurrent.value = val
			getEvaluation(evaluationType.value)
		}
		//获取热销店铺推荐
		const getSalestore = () => {
			let params = {
				storeId: goodsDetail.data.storeInf.storeId,
				sort: 7,
				pageSize: salesPageSize.value,
				current: salesCurrent.value
			}
			proxy.$get('v3/goods/front/goods/goodsList', params).then(res => {
				if (res.state == 200) {
					let result = res.data
					recommendeData.data = result
					recommendedList.data = result.list
					recommendedList.data.map(
						item => (item.goodsPrice = new Number(item.goodsPrice).toFixed(2))
					)
				} else {
					ElMessage.error(res.msg)
				}
			})
		}
		//店铺热销列表上一页
		const handlePrevCilickChangeSales = () => {
			if (evaluationCurrent.value == 1) {
				salesCurrent.value = 1
			} else {
				salesCurrent.value--
			}
			getSalestore()
		}
		//店铺热销列表下一页
		const handleNextCilickChangeSales = () => {
			if (salesCurrent.value >= goodsCommentsInfo.data.list.length) {
				salesCurrent.value = goodsCommentsInfo.data.list.length
			} else {
				salesCurrent.value++
			}
			getSalestore()
		}
		//店铺热销列表当前选择页
		const handleCurrentChangeSales = val => {
			salesCurrent.value = val
			getSalestore()
		}
		//关注店铺及取消关注
		const focusStore = () => {
			if (store.state.loginFlag) {
				//已登录
				let params = {
					storeIds: goodsDetail.data.storeInf.storeId,
					isCollect: !goodsDetail.data.storeInf.isFollowStore
				}
				proxy.$post('v3/member/front/followStore/edit', params).then(res => {
					if (res.state == 200) {
						goodsDetail.data.storeInf.isFollowStore = !goodsDetail.data.storeInf
							.isFollowStore
						// proxy.$refs.headercat.storeData.info.isFollow = goodsDetail.data
						// 	.storeInf.isFollowStore
						// 	? 'true'
						// 	: 'false'
						if (goodsDetail.data.storeInf.isFollowStore) {
							proxy.$sldStatEvent({
								behaviorType: 'fol',
								storeId: goodsDetail.data.storeInf.storeId
							})
						}
					}
				})
			} else {
				//未登录提示登录
				loginModalVisibleFlag.value = true
			}
		}
		//商品收藏及取消收藏
		const collectGoods = () => {
			if (store.state.loginFlag) {
				//已登录
				let params = {
					productIds: productId.value,
					isCollect: !goodsDetail.data.followGoods
				}
				proxy.$post('v3/member/front/followProduct/edit', params).then(res => {
					if (res.state == 200) {
						goodsDetail.data.followGoods = !goodsDetail.data.followGoods
						if (goodsDetail.data.followGoods) {
							proxy.$sldStatEvent({
								behaviorType: 'fav',
								goodsId: goodsDetail.data.goodsId,
								storeId: goodsDetail.data.storeInf.storeId
							})
						}
					} else {
						ElMessage.error(res.msg)
					}
				})
			} else {
				//未登录提示登录
				loginModalVisibleFlag.value = true
			}
		}

		//打开优惠券弹框
		const openCouponModel = () => {
			couponModel.value = true
			getCouponList()
		}
		//上一页优惠券
		const handlePrevCilickChangeCoupon = () => {
			curCouponPage.value--
			getCouponList()
		}
		//下一页优惠券
		const handleNextCilickChangeCoupon = () => {
			curCouponPage.value++
			getCouponList()
		}
		//当前页数优惠券
		const handleCurrentChangeCoupon = () => {
			getCouponList()
		}
		//点击查看全部查看全部的商品规格参数
		const openParameter = () => {
			parameterShow.value = !parameterShow.value
		}

		//选择商品主图
		const selectDefaultImage = (goodsImgItem, goodsImgIndex) => {
			currentDefaultImageShow.value = false
			defaultImage.value = goodsImgItem
			currentDefaultImage.value = goodsImgIndex
		}
		// 点击商品主图
		const clickDefaultImage = () => {
			lunboShow.value = true
		}

		//切换商品主图
		const switchDefaultImage = type => {
			let defaultImagelength = goodsDetail.data.defaultProduct.goodsPics.length
			if (type == 'left') {
				currentDefaultImage.value--
				if (currentDefaultImage.value <= 0) {
					currentDefaultImage.value = 0
				}
				defaultImage.value =
					goodsDetail.data.defaultProduct.goodsPics[currentDefaultImage.value]
			} else {
				currentDefaultImage.value++
				if (currentDefaultImage.value >= defaultImagelength) {
					currentDefaultImage.value = defaultImagelength - 1
				}
				defaultImage.value =
					goodsDetail.data.defaultProduct.goodsPics[currentDefaultImage.value]
			}
			goodsPictureListsLeft()
		}
		//图片列表的left移动的距离
		const goodsPictureListsLeft = () => {
			//获取 goods_picture_list 的元素
			let goodsPictureLists = goodsPictureList.value
			//列表默认显示5张图片
			if (
				goodsDetail.data.defaultProduct.goodsPics.length > 5 &&
				currentDefaultImage.value >= 0
			) {
				/* 分析找规律：
					如果有8张图片，点击右键 最大可以向左移动的距离为 8 - 5既3张图，left移动的距离为 3 * - 66px; currentDefaultImage.value从0开始的
					其中：66px为每一个元素需要每次移动的距离 为图片的宽度与图片之间的间距的和
					currentDefaultImage.value == 4；goodsPictureLists.style.left = 0；
					currentDefaultImage.value == 5；goodsPictureLists.style.left = (5-4) (1) * -66px；
					currentDefaultImage.value == 6；goodsPictureLists.style.left = (5-2) (2) * -66px；
					currentDefaultImage.value == 7；goodsPictureLists.style.left = (5-3) (2) * -66px；
					 点击左键，最大可以向右移动的距离为 8 - 5 即3张
					 currentDefaultImage.value == 0；goodsPictureLists.style.left = 0；
					 currentDefaultImage.value == 1； goodsPictureLists.style.left = -66px；
					 currentDefaultImage.value == 2; goodsPictureLists.style.left = 2 * -66px；
					 currentDefaultImage.value == 3; goodsPictureLists.style.left = 3 * -66px；
				*/
				if (currentDefaultImage.value > 4) {
					goodsPictureLists.style.left =
						(currentDefaultImage.value - 4) * -66 + 'px'
				}
				if (
					currentDefaultImage.value <
					goodsDetail.data.defaultProduct.goodsPics.length - 4
				) {
					goodsPictureLists.style.left = currentDefaultImage.value * -66 + 'px'
				}
			}
		}

		//获取元素距离父元素的顶部及左边的距离
		const offset = el => {
			let top = el.offsetTop
			let left = el.offsetLeft
			if (el.offsetParent) {
				el = el.offsetParent
				top += el.offsetTop
				left += el.offsetLeft
			}
			return {
				left: left,
				top: top
			}
		}

		//鼠标移动
		const move = e => {
			if (currentDefaultImage.value == -1) {
				return
			}

			//主图父元素的信息 宽，高
			let previewsBox = previewBox.value
			let previewBoxWidth = previewsBox.offsetWidth
			let previewBoxHeight = previewsBox.offsetHeight
			//主图父元素距离顶部的距离
			let previewsBoxLeft = offset(previewsBox).left
			let previewsBoxTop = offset(previewsBox).top

			// 遮罩盒子的信息宽，高
			let masksBox = maskBox.value
			let maskBoxWidth = masksBox.offsetWidth
			let maskBoxHeight = masksBox.offsetHeight
			//鼠标距离屏幕距离
			let moveX = e.clientX
			let moveY = e.clientY

			//获取左侧大图父元素的信息
			let zoomsBox = zoomBox.value
			let zoomBoxWidth = zoomsBox.offsetWidth
			let zoomBoxHeight = zoomsBox.offsetHeight

			// 获取大图元素的信息宽，高
			let pictureBigBox = pictureBig.value
			let pictureBigWidth = pictureBigBox.offsetWidth
			let pictureBigHeight = pictureBigBox.offsetHeight

			//获取滚动条的高度
			let scroll = document.documentElement.scrollTop || document.body.scrollTop

			//主图距离父元素的left及top值
			let left = moveX - previewsBoxLeft - maskBoxWidth / 2
			let top
			if (scroll > 0) {
				top = moveY - previewsBoxTop + scroll - maskBoxHeight / 2
			} else {
				top = moveY - previewsBoxTop - maskBoxHeight / 2
			}

			//移动限制最大宽度，及最大高度
			let maxWidth = previewBoxWidth - maskBoxWidth
			let maxHeight = previewBoxHeight - maskBoxHeight

			left = left < 0 ? 0 : left > maxWidth ? maxWidth : left
			top = top < 0 ? 0 : top > maxHeight ? maxHeight : top

			//比列
			let parcentX = left / maxWidth
			let parcentY = top / maxHeight

			//遮罩层的定位值
			maskBox.value.style.left = left + 'px'
			maskBox.value.style.top = top + 'px'

			//大图元素的定位值
			pictureBig.value.style.left =
				parcentX * (zoomBoxWidth - pictureBigWidth) + 'px'
			pictureBig.value.style.top =
				parcentY * (zoomBoxHeight - pictureBigHeight) + 'px'
			pictureBig.value.style.width =
				(previewBoxWidth / maskBoxWidth) * zoomBoxWidth + 'px'
			pictureBig.value.style.height =
				(previewBoxHeight / maskBoxHeight) * zoomBoxHeight + 'px'
		}
		//鼠标移出
		const out = () => {
			maskShow.value = false
		}

		//鼠标移入
		const enter = () => {
			if (currentDefaultImage.value == -1) {
				return
			}
			addrDialogVisible.value = false
			maskShow.value = true
		}

		const kefu = () => {
			if (store.state.loginFlag) {
				let chatInfo = {
					storeId: goodsDetail.data.storeInf.storeId,
					vendorAvatar: goodsDetail.data.storeInf.storeLogo,
					storeName: goodsDetail.data.storeInf.storeName,
					source: L['从商品详情页进入'],
					sourceType: 'goods',
					showData: {
						productId: goodsDetail.data.defaultProduct.productId,
						goodsName: goodsDetail.data.goodsName,
						goodsImage: goodsDetail.data.shareImage,
						productPrice: goodsDetail.data.defaultProduct.productPrice,
						goodsId: goodsDetail.data.goodsId
					}
				}
				store.commit('saveChatBaseInfo', chatInfo)

				let newWin = router.resolve({
					path: '/service',
					query: {
						vid: goodsDetail.data.storeInf.storeId
					}
				})

				window.open(newWin.href, '_blank')
			} else {
				//未登录提示登录
				loginModalVisibleFlag.value = true
			}
		}

		//进入店铺
		const goStore = () => {
			let tarUrl = router.resolve({
				path: '/store/goods',
				query: {
					vid: vid.value
				}
			})
			window.open(tarUrl.href, '_blank')
		}

		// 评论区查看图片
		const showImg = (index, img) => {
			imgSource.value = img
			imgIndex.value = index
			imgVisible.value = true
		}

		//分享
		const share = type => {
			let title = goodsDetail.data.goodsName //需要分享的标题,这里取商品名字
			let url = goodsDetail.data.shareLink //分享的地址，用户点击可以进入到该商品
			let content = goodsDetail.data.goodsBrief //自定义内容,这里取商品广告词
			let targetUrl = '' //跳转的url地址
			if (type == 'weixin') {
				wxShareCode.value = true
				//微信
				let canvas = qrcanvas({
					data: url, //二维码内容
					size: 100,
					colorDark: 'red'
				})
				let share_wx_qrcode = document.getElementById('share_wx_qrcode')
				if (
					wxShareCode.value &&
					share_wx_qrcode != null &&
					share_wx_qrcode != undefined
				) {
					document.getElementById('share_wx_qrcode').innerHTML = ''
					document.getElementById('share_wx_qrcode').appendChild(canvas)
				}
			} else if (type == 'qzone') {
				wxShareCode.value = false
				//QQ空间
				targetUrl =
					'https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?title=' +
					encodeURIComponent(title) +
					'&desc=' +
					encodeURIComponent(content) +
					'&summary=' +
					encodeURIComponent(content) +
					'&url=' +
					encodeURIComponent(url)
				window.open(targetUrl, '_blank')
			} else if (type == 'sina') {
				wxShareCode.value = false
				//新浪微博
				targetUrl =
					'https://service.weibo.com/share/share.php?title=' +
					encodeURIComponent(content + '「' + title + '」' + L[' 点这里'] + url)
				window.open(targetUrl, '_blank')
			}
		}

		//获取地址列表
		const getAddressList = () => {
			proxy
				.$get('v3/member/front/memberAddress/list', {
					pageSize: 1000
				})
				.then(res => {
					if (res.state == 200) {
						if (res.data.list.length > 0) {
							address_list.data = res.data.list
							if (address_list.data.findIndex(i => i.isDefault == 1) > 0) {
								let index = address_list.data.findIndex(i => i.isDefault == 1)
								curAddrName.value =
									address_list.data[index].addressAll +
									'' +
									address_list.data[index].detailAddress
								curAddr.value = address_list.data[index].addressId
								regionCode.value = address_list.data[index].cityCode
							} else {
								curAddrName.value =
									address_list.data[0].addressAll +
									'' +
									address_list.data[0].detailAddress
								curAddr.value = address_list.data[0].addressId
								regionCode.value = address_list.data[0].cityCode
							}

							if (localStorage.getItem('addressId')) {
								let addressID = localStorage.getItem('addressId')
								if (res.data.list.filter(i => i.addressId == addressID)[0]) {
									let tmp = res.data.list.filter(
										i => i.addressId == addressID
									)[0]
									curAddr.value = tmp.addressId
									curAddrName.value = tmp.addressAll + '' + tmp.detailAddress
									regionCode.value = tmp.cityCode
								}
							}
						} else {
							curAddrName.value = ''
							addrIdx.value = 1
						}
					}
				})
		}

		//修改选择地址列表
		const changeAddr = e => {
			curAddr.value = e
			let tmp = address_list.data.filter(i => i.addressId == e)[0]
			curAddrName.value = tmp.addressAll + '' + tmp.detailAddress
			addrDialogVisible.value = false
			regionCode.value = tmp.cityCode
			getUserEx(regionCode.value)
		}

		// 选择其他地址的顶部选项
		const selRegion = index => {
			otherAddrIdx.value = index
			switch (index) {
				case 0: {
					othAddrDe.value = addrData
					break
				}
				case 1: {
					othAddrDe.value = addrData[othTopIdx['0']].children
					break
				}
				case 2: {
					othAddrDe.value =
						addrData[othTopIdx['0']].children[othTopIdx['1']].children
					break
				}
			}
			otherTree.value[otherAddrIdx.value] =
				othAddrDe.value[othTopIdx[otherAddrIdx.value]]
		}

		// 选择其他地址的顶部选项下的子选项
		const selRegionChild = (index, otherIdx) => {
			othTopIdx[otherIdx] = index
			otherTree.value[otherAddrIdx.value] = othAddrDe.value[othTopIdx[otherIdx]]
			if (
				othAddrDe.value[othTopIdx[otherAddrIdx.value]].children &&
				othAddrDe.value[othTopIdx[otherAddrIdx.value]].children.length
			) {
				otherTree.value.splice(otherAddrIdx.value + 1)
				othTopIdx[otherIdx + 1] = 0
				selRegion(otherAddrIdx.value + 1)
			} else {
				// 中国三级选择最后区域的时候用
				// regionCode.value = otherTree.value[otherAddrIdx.value - 1].regionCode
				// 哈萨克斯塔二级的选择最后区域的时候用
				regionCode.value = otherTree.value[otherAddrIdx.value].regionCode
				addrDialogVisible.value = false
				curAddrName.value = ''
				otherTree.value.map(i => {
					curAddrName.value += i.regionName
				})
				getUserEx(regionCode.value)
			}
		}

		//用于切换用户的地址，获取信运费等
		const getUserEx = (cityCode) => {

			if (!cityCode) {
				return
			}


			proxy
				.$get('v3/goods/front/goods/calculateExpress', {
					cityCode,
					productId: productId.value,
					num: currentSpecNum.value

				})
				.then(res => {
					if (res.state == 200) {
						goodsDetail.data.deliverInfo.expressFee = res.data.toFixed(2)
					}
				})
			if (addressId >= 0) {
				proxy
					.$post('v3/member/front/memberAddress/changeDefaultAddress', {
						addressId,
						isDefault: 1
					})
					.then(() => { })
					.catch(() => { })
			}
		}

		const refreshInfo = () => {
			history.go(0)
		}

		const updateFllow = e => {
			goodsDetail.data.storeInf.isFollowStore = e.state == 'true' ? true : false
		}

		const goodsParameterMore = () => {
			goodsParameterShow.value = true
			moreShow.value = false
		}
		const goodsHtmlShow = ref(false)
		const goodsHtmlMore = () => {
			goodsHtmlShow.value = true;
		}
		const lunboShow = ref(false)

		const couponShow = ref(false)
		const clickCoupon = () => {
			couponShow.value = !couponShow.value;
		}

		//领取优惠卷
		const receive = couponId => {
			if (!store.state.loginFlag) {
				loginModalVisibleFlag.value = true
				return
			}
			proxy
				.$get("v3/promotion/front/coupon/receiveCoupon", { couponId })
				.then(res => {
					if (res.state == 200) {
						ElMessage.success(L["领取成功"]);
						emit('refreshCouponList')
					} else {
						ElMessage(res.msg);
						emit('refreshCouponList')
					}
				})
				.catch(() => {
					//异常处理
				});
		};

		//暴露的变量及方法
		return {
			L,
			receive,
			couponShow,
			clickCoupon,
			clickDefaultImage,
			lunboShow,
			goodsHtmlShow,
			goodsHtmlMore,
			storeData,
			moreShow,
			goodsParameterMore,
			agreementShow,
			goodsParameterShow,
			show_box1,
			show_box2,
			productId,
			goodsDetail,
			isChoice,
			fullDisList,
			pictureBig,
			maskBox,
			previewBox,
			zoomBox,
			maskShow,
			defaultImage,
			selectSpecVal,
			move,
			out,
			enter,
			getFullDiscountList,
			selectDefaultImage,
			switchDefaultImage,
			currentDefaultImage,
			currentDefaultImageShow,
			couponList,
			currentSpecNum,
			editNum,
			addCart,
			goBuy,
			recommendeList,
			getStorePopular,
			storePopularList,
			storePopularType,
			goodsDesctionType,
			goodsDescType,
			goodsCommentsInfo,
			evaluationType,
			getEvaluation,
			recommendedList,
			evaluationCurrent,
			evaluationPageSize,
			handlePrevCilickChange,
			handleNextCilickChange,
			handleCurrentChange,
			salesPageSize,
			salesCurrent,
			handlePrevCilickChangeSales,
			handleNextCilickChangeSales,
			handleCurrentChangeSales,
			focusStore,
			collectGoods,
			goGoodsDetail,
			vid,
			recommendeData,
			openCouponModel,
			couponModel,
			cartListData,
			loginModalVisibleFlag,
			closeLoingModal,
			firstLoading,
			container,
			containerTop,
			containCon,
			kefu,
			lookFullDiscount,
			fullDiscountModel,
			goStore,
			goodsPictureList,
			handlePrevCilickChangeCoupon,
			handleNextCilickChangeCoupon,
			handleCurrentChangeCoupon,
			curCouponPage,
			couponPageSize,
			share,
			wxShareCode,
			openParameter,
			parameterShow,
			disStyle,
			score,
			colors,
			imgVisible,
			showImg,
			imgSource,
			recomOffShop,
			preSellInfo,
			pinInfo,
			seckillInfo,
			time,
			sorcRemind,
			ladderInfo,
			genQrcode,
			isShowQr,
			judgeStock,
			playV,
			address_list,
			curAddr,
			changeAddr,
			curAddrName,
			addrIdx,
			addrData,
			otherAddrIdx,
			otherTree,
			othAddrDe,
			othTopIdx,
			selRegion,
			selRegionChild,
			addrDialogVisible,
			logFlag,
			refreshInfo,
			updateFllow
		}
	}
}
</script>

<style lang="scss">
@import '../../style/base.scss';
@import '../../style/iconfont.css';
@import '../../style/goodsDetail.scss';

.sld_goods_detail {
	.goods_html {
		p {
			line-height: 20px;
		}
	}
}

.popular_list_empty {
	height: 95px;

	font-size: 14px;
	font-family: Microsoft YaHei;
	font-weight: 400;
	color: #666666;
}

.imageBorder {
	border: 1px solid #eee;
}

.goods_picture_big {
	border: 1px solid #eee;
}

.el-radio__inner:hover {
	border-color: var(--color_main);
}

.el-radio__input.is-checked .el-radio__inner {
	border-color: var(--color_main);
	background: var(--color_main_bg);
}

.el-radio__input.is-checked+.el-radio__label {
	color: var(--color_main);
}

.el-radio {
	margin-bottom: 10px;
	display: flex;
	align-items: flex-start;
	white-space: unset;
	margin-right: unset;
}

.el-radio-button__inner,
.el-radio-group {
	/* display: block; */
	line-height: 1;
	vertical-align: middle;
}

.el-radio__label {
	font-size: 13px;
	width: 320px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	word-break: break-all;
	line-height: 22px;
	margin-top: -5px;
}

.evaluationes {
	color: #3b4 !important;
}

.sld_goods_detail .goods_htmls .ql-video {
	width: 525px;
	height: 315px;
}

.sld_goods_detail .goods_htmls a {
	display: inline-block;
	margin: 5px auto;
	color: #0000ff;
	text-decoration: underline;
}

.sld_goods_detail .goods_htmls table {
	border-collapse: collapse;
	padding: 0;
}

.sld_goods_detail .goods_htmls td,
.sld_goods_detail .goods_htmls th {
	border: 1px solid #ddd;
	padding: 5px 10px;
}

.sld_goods_detail .goods_htmls ol,
.sld_goods_detail .goods_htmls ul {
	padding-left: 10px;
}

.sld_goods_detail .goods_htmls ol li,
.sld_goods_detail .goods_htmls ul li {
	list-style: unset;
}

.sld_goods_detail {
	.summary {
		.coupon {
			.el-dialog__header {
				padding-top: 18px;
				padding-bottom: 18px;

				.el-dialog__title {
					color: #333333;
					font-family: Microsoft YaHei;
					font-weight: bold;
				}

				.el-dialog__close {
					color: #333333;
					font-size: 20px;
				}
			}

			.el-dialog__body {
				background: #f8f8f8;
			}
		}
	}
}

$breakpoint: 480px;
// You also need to change the JS breakpoint.


.swiper-container {
	overflow: hidden;
	width: 100%;
	margin-left: auto;
	margin-right: auto;

	@media (min-width: $breakpoint) {
		min-height: 320px;
	}

	&-wrapper {
		display: flex;
		flex-flow: column nowrap;

		@media (min-width: $breakpoint) {
			flex-flow: row nowrap;
		}

		height: 100vh;
		width: 100vw;
	}
}

.swiper-button {

	&-next,
	&-prev {
		color: #000;
	}
}

.swiper-slide {
	text-align: center;
	background-size: cover;
	background-position: center;
	background-color: #fff;

	/* Center slide text vertically */
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;

	/* Slide content */
	.description,
	.title {
		display: block;
		opacity: 0;
		transition: 0.5s ease 0.5s;
	}

	&-active {

		.description,
		.title {
			opacity: 1;
		}

		.title {
			margin-bottom: 0.5rem;
			font-size: 24px;
			color: #000;
			transition: opacity 0.5s ease 0.5s;
		}

		.description {
			font-size: 16px;
			color: #777;
			transition: opacity 0.5s ease 0.75s;
		}
	}
}

.gallery-top {
	position: relative;
	width: 100%;
	height: 75vh;

	@media (min-width: $breakpoint) {
		width: 80%;
		height: 100vh;
		margin-right: 10px;
	}
}

.gallery-thumbs {
	width: 100%;
	height: 25vh;
	padding-top: 10px;

	@media (min-width: $breakpoint) {
		width: 20%;
		height: 100vh;
		padding: 0;
	}

	.swiper-wrapper {
		flex-direction: row;

		@media (min-width: $breakpoint) {
			flex-direction: column;
		}
	}

	.swiper-slide {
		width: 25%;
		flex-flow: row nowrap;

		@media (min-width: $breakpoint) {
			flex-flow: column nowrap;
			width: 100%;
		}

		height: 100%;
		opacity: 0.75;
		cursor: pointer;
	}

	.swiper-slide-thumb-active {
		opacity: 1;
	}
}
</style>
